import React, { useEffect, useState } from "react";
import axios from "axios";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./hof.css";

import { API_URLS, BASE_URL } from "../../../../Utils";
import ReadMoreHOF from "../Pop-ups/ReadMoreHOF/ReadMoreHOF";

const HallofFame = () => {
  const [hallOfFames, setHallOfFames] = useState([]);
  const [showReadMore, setShowReadMore] = useState(false);
  const [selectedDescription, setSelectedDescription] = useState("");
  const [selectedImage, setSelectedImage] = useState("");

  const openReadMoreModal = (Description, Image) => {
    setSelectedDescription(Description);
    setSelectedImage(Image);
    setShowReadMore(true);
  };
  const closeReadMoreModal = () => {
    setShowReadMore(false);
    setSelectedDescription(null);
    setSelectedImage(null);
  };

  const settings = {
    infinite: true,
    dots: true,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 4000,
    slidesToShow: 1,
    slidesToScroll: 1,
    pauseOnHover: false,
  };

  const getHallByYearHandler = async (Year) => {
    try {
      const {
        data: { data },
      } = await axios.get(API_URLS.GET_HALL_OF_FAME_BY_YEAR, {
        params: {
          Year: 2024,
        },
      });
      // console.log("data", data.result);
      setHallOfFames(data.result);
    } catch (error) {
      alert(error.response.data.message);
    }
  };

  useEffect(() => {
    getHallByYearHandler();
  }, []);

  return (
    <section className="team_section" id="hof">
      <Slider {...settings} className="team_slick">
        {hallOfFames.map((item) => (
          <div key={item.HallOfFameID} className="slick_item">
            <div className="himage">
              <img
                src={`${BASE_URL}/api/v1/files/HOFPhoto/${item.Name}`}
                alt=""
                className=""
              />
            </div>

            <div className="content">
              <div className="container">
                <div style={{ display: "flex", gap: "10px" }}>
                  <h3>Hall OF FAME</h3>

                  <h6 style={{ color: "white", paddingTop: "20px" }}>
                    ({item.Year})
                  </h6>
                </div>

                <a
                  className="btn btn_primary br-50"
                  style={{ marginTop: "-50px" }}
                  onClick={() =>
                    openReadMoreModal(
                      item.Description,
                      `${BASE_URL}/api/v1/files/HOFPhoto/${item.Name}`
                    )
                  }
                >
                  Read More
                </a>
                {showReadMore && (
                  <ReadMoreHOF
                    isOpen={showReadMore}
                    onClose={closeReadMoreModal}
                    Description={selectedDescription}
                    Image={selectedImage}
                  />
                )}
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </section>
  );
};

export { HallofFame };
