import React, { useState, useEffect } from "react";
import "react-datepicker/dist/react-datepicker.css";
import Modal from "react-modal";
import DatePicker from "react-datepicker";
import TimePicker from "react-time-picker";
import "react-time-picker/dist/TimePicker.css";
import { API_URLS, BASE_URL } from "../../../../Utils";

const ScheduleReg = ({ isOpen, onClose, onAdd }) => {
  const [Year, setYear] = useState("2025");
  const [tournamentList, setTournamentList] = useState([]);
  const [teamList, setTeamList] = useState([]);
  const [SeasonOrTour, setSeasonOrTour] = useState("");
  const [BracketNumber, setBracketNumber] = useState("0");
  const [DayOfWeek, setDayOfWeek] = useState("");
  const [Division, setDivision] = useState("0");
  const [date, setDate] = useState("");
  const [Time, setTime] = useState("");
  const [VisitorTeam, setVisitorTeam] = useState("");
  const [VisitorScores, setVisitorScores] = useState("");
  const [HomeScores, setHomeScores] = useState("");
  const [HomeTeam, setHomeTeam] = useState("");
  const [GameNumber, setGameNumber] = useState("");
  const [FieldNumber, setFieldNumber] = useState("");
  const [SelectedParkId, setSelectedParkId] = useState("");
  const [SelectedTournament, setSelectedTournament] = useState("");
  const [SelectedVisitorTeam, setSelectedVisitorTeam] = useState("");
  const [SelectedVisitorTeamId, setSelectedVisitorTeamId] = useState("");
  const [SelectedHomeTeam, setSelectedHomeTeam] = useState("");
  const [SelectedHomeTeamId, setSelectedHomeTeamId] = useState("");
  const [isfieldNumber, setIsFieldNumber] = useState(0);
  const [isHidden, setIsHidden] = useState(window.innerWidth <= 768);

  useEffect(() => {
    fnTournamentList();
  }, [Year]);
  useEffect(() => {
    const handleResize = () => {
      setIsHidden(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const formData = {
    Year,
    SeasonOrTour: SelectedTournament,
    BracketNumber,
    DayOfWeek,
    Type: "Tournament",
    Division,
    Date: date,
    Time,
    ParkId: SelectedParkId,
    VisitorTeam: SelectedVisitorTeam,
    VisitorScores,
    HomeScores,
    HomeTeam: SelectedHomeTeam,
    GameNumber,
    FieldNumber,
    VisitorTeamID: SelectedVisitorTeamId,
    HomeTeamID: SelectedHomeTeamId,
  };
  const handleTimeChange = (newTime) => {
    setTime(newTime);
  };

  const fnTournamentList = () => {
    const url = `${BASE_URL}/api/v1/web/park/get_all_proam_tournaments_by_year?year=${Year}`;
    fetch(url)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Network response was not ok.");
        }
      })
      .then((data) => {
        setTournamentList(data);
      })
      .catch((error) => {
        console.error(error.message);
      });
  };
  // const handleTimeBlur = () => {
  //   const formattedTime = formatTime(Time);
  //   setTime(formattedTime);
  // };

  const formatTime = (time) => {
    let [hourPart, minutePart] = time.split(":");
    let minutes, period;
    // console.log("Timeformat", Time);
    // Extract period (AM/PM) if present
    if (minutePart) {
      [minutes, period] = minutePart.split(" ");
      period = period ? period.toUpperCase() : "AM";
    } else {
      minutes = "00";
      period = "AM";
    }

    let hours = parseInt(hourPart, 10);

    if (period !== "AM" && period !== "PM") {
      period = hours >= 12 ? "PM" : "AM";
    }

    if (hours > 12) {
      hours -= 12;
      period = "PM";
    } else if (hours === 12) {
      period = period === "AM" ? "PM" : "AM";
    } else if (hours === 0) {
      hours = 12;
      period = "AM";
    }

    hours = String(hours).padStart(2, "0");
    minutes = minutes.padStart(2, "0");

    return `${hours}:${minutes} ${period}`;
  };

  const getAllTeams = (SelectedParkId) => {
    const url = `${BASE_URL}/api/v1/web/team/Get_Team_By_ParkId_With_Type?SelectedParkId=${SelectedParkId}&Type=Tournament`;
    fetch(url)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Network response was not ok.");
        }
      })
      .then((data) => {
        setTeamList(data);
      })
      .catch((error) => {
        console.error(error.message);
      });
  };

  const handleDateChange = (selectedDate) => {
    setDate(selectedDate);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formattedDate = date ? date.toISOString() : "";

    const { SeasonOrTour, BracketNumber, VisitorTeam, HomeTeam, DayOfWeek } =
      formData;
    if (!SeasonOrTour.trim()) {
      alert("Please Select Tournament from Drop-Down.", "", "info");
      return;
    }
    if (!BracketNumber.trim() || BracketNumber === "0") {
      alert("Please Select Bracket Number from Drop-Down.", "", "info");
      return;
    }
    if (!DayOfWeek.trim() || DayOfWeek === "0") {
      alert("Please Select Day from Drop-Down.", "", "info");
      return;
    }
    if (!VisitorTeam.trim() || SelectedVisitorTeam === "0") {
      alert("Please Select Visitor Team from Drop-Down.", "", "info");
      return;
    }
    if (!HomeTeam.trim() || SelectedHomeTeam === "0") {
      alert("Please Select Home Team from Drop-Down.", "", "info");
      return;
    }

    if (!formattedDate.trim()) {
      alert("Please enter Date.");
      return;
    }
    if (!Time || !Time.trim()) {
      alert("Please select a time.", "", "info");
      return;
    }
    try {
      // debugger;
      //console.log("Formdata", formData);
      const url = API_URLS.ADD_SCHEDULE;
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        alert("Added Successfully");
        onAdd();
        onClose();
      } else {
        const errorData = await response.json();
        alert(`Error: ${errorData.message}`);
      }
    } catch (error) {
      console.error("Error:", error);
      alert("An error occurred while processing the request.");
    }
  };
  const customStyles = {
    content: {
      width: "80%", // Adjust this value to your desired width
      // Optional: Set a maximum width
      // Center the modal horizontally
      padding: "20px",
      overflowY: "auto",
      maxHeight: "96%",
      margin: "auto",

      // Add some padding
      // Add other styles as needed
    },
  };
  const responsive = {
    container: {
      display: "flex",
      alignItems: "center",
    },
    "@media (max-width: 768px)": {
      container: {
        display: "none",
      },
    },
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="Rules Modal"
      className="modal-content"
      overlayClassName="modal-overlay orange-scrollbar"
      style={{ ...customStyles }}
    >
      <div class="form_wrap teamReg_form ">
        <div className="close-button">
          <h3>Schedule Registration</h3>
          <svg
            width="30"
            height="30"
            viewBox="0 0 24 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg "
            onClick={onClose}
          >
            <path
              d="M12 23.25C6.07 23.25 1.25 18.43 1.25 12.5C1.25 6.57 6.07 1.75 12 1.75C17.93 1.75 22.75 6.57 22.75 12.5C22.75 18.43 17.93 23.25 12 23.25ZM12 3.25C6.9 3.25 2.75 7.4 2.75 12.5C2.75 17.6 6.9 21.75 12 21.75C17.1 21.75 21.25 17.6 21.25 12.5C21.25 7.4 17.1 3.25 12 3.25Z"
              fill="#1B1B1B"
            />
            <path
              d="M9.17035 16.08C8.98035 16.08 8.79035 16.01 8.64035 15.86C8.35035 15.57 8.35035 15.09 8.64035 14.8L14.3004 9.13999C14.5904 8.84999 15.0704 8.84999 15.3604 9.13999C15.6504 9.42999 15.6504 9.90998 15.3604 10.2L9.70035 15.86C9.56035 16.01 9.36035 16.08 9.17035 16.08Z"
              fill="#1B1B1B"
            />
            <path
              d="M14.8304 16.08C14.6404 16.08 14.4504 16.01 14.3004 15.86L8.64035 10.2C8.35035 9.90998 8.35035 9.42999 8.64035 9.13999C8.93035 8.84999 9.41035 8.84999 9.70035 9.13999L15.3604 14.8C15.6504 15.09 15.6504 15.57 15.3604 15.86C15.2104 16.01 15.0204 16.08 14.8304 16.08Z"
              fill="#1B1B1B"
            />
          </svg>
        </div>
        <form
          onSubmit={handleSubmit}
          class="row"
          style={{ marginRight: "10px", marginLeft: "1px" }}
        >
          <div className="col-md-6 input_group">
            <label htmlFor="year">Year</label>
            <select
              value={Year}
              onChange={(e) => setYear(e.target.value)}
              className="input input_select"
            >
              <option value="2021">2021</option>
              <option value="2022">2022</option>
              <option value="2023">2023</option>
              <option value="2024">2024</option>
              <option value="2025">2025</option>
            </select>
          </div>
          <div className="col-md-12 input_group">
            <label htmlFor="selectedTournament">
              Select Tournament
              <span className="edd-required-indicator">*</span>
            </label>
            <select
              id="selectedTournament"
              value={SelectedParkId}
              style={{ width: "100%" }}
              onChange={(e) => {
                const selectedId = e.target.value;

                // Find the selected tournament
                const selectedTournament = tournamentList.data.result.find(
                  (tournament) => {
                    const parkIdString = String(tournament.ParkId); // Convert to string

                    return parkIdString === selectedId;
                  }
                );

                setSelectedTournament(selectedTournament?.FirstName || "");
                setSelectedParkId(selectedId);

                getAllTeams(selectedId);
              }}
              className="input input_select"
            >
              <option value="">Select Tournament</option>
              {tournamentList.data?.result.map((tournament) => (
                <option key={tournament.ParkId} value={tournament.ParkId}>
                  {tournament.FirstName}
                </option>
              ))}
            </select>
          </div>
          <div className="col-md-6 input_group">
            <label htmlFor="Bracket">
              Bracket<span className="edd-required-indicator">*</span>
            </label>
            <select
              id="Bracket"
              className="input input_select"
              value={BracketNumber}
              onChange={(e) => setBracketNumber(e.target.value)}
            >
              <option selected="selected" value="0">
                Select
              </option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
              <option value="6">6</option>
              <option value="7">7</option>
              <option value="8">8</option>
              <option value="9">9</option>
              <option value="10">10</option>
              <option value="11">11</option>
              <option value="12">12</option>
              <option value="13">13</option>
              <option value="14">14</option>
              <option value="15">15</option>
              <option value="16">16</option>
              <option value="17">17</option>
              <option value="18">18</option>
              <option value="19">19</option>
              <option value="20">20</option>
            </select>
          </div>
          <div className="col-md-6 input_group">
            <label htmlFor="ddldays">Day *</label>
            <select
              id="ddldays"
              className="input input_select"
              value={DayOfWeek}
              onChange={(e) => setDayOfWeek(e.target.value)}
            >
              <option selected="selected" value="0">
                Select
              </option>
              <option Value="Monday">Monday</option>
              <option value="Tuesday">Tuesday</option>
              <option value="Wednesday">Wednesday</option>
              <option value="Thursday">Thursday</option>
              <option value="Friday">Friday</option>
              <option value="Saturday">Saturday</option>
              <option value="Sunday">Sunday</option>
            </select>
          </div>
          <div className="col-md-6 input_group">
            <label htmlFor="ddlDivision">Division</label>
            <select
              id="ddlDivision"
              style={{ width: "100%" }}
              value={Division}
              onChange={(e) => setDivision(e.target.value)}
              className="input input_select"
            >
              <option selected="selected" value="0">
                Select
              </option>
              <option Value="A">A</option>
              <option value="B">B</option>
              <option value="C">C</option>
              <option value="D">D</option>
              <option value="D Adv">D Adv</option>
              <option value="D Rec">D Rec</option>
              <option value="E">E</option>
            </select>
          </div>
          <div className="col-md-6 input_group">
            <label>Date *</label>
            <DatePicker
              selected={date ? new Date(date) : null}
              onChange={handleDateChange}
              className="input"
            />
          </div>
          <div className="col-md-6 input_group">
            <label htmlFor="txtTime">Time *</label>
            <TimePicker
              id="txtTime"
              value={Time}
              onChange={handleTimeChange}
              format="hh:mm a" // 12-hour format with AM/PM
              clockIcon={null} // Optional: Remove default clock icon if needed
              disableClock={true} // Ensure the clock is enabled
            />
          </div>
          <div className="col-md-6 input_group">
            <label htmlFor="ddlVisitorTeamNames">
              Visitor Team Name{" "}
              <span className="edd-required-indicator">*</span>
            </label>
            <select
              id="ddlVisitorTeamNames"
              style={{ width: "100%" }}
              value={SelectedVisitorTeamId}
              onChange={(e) => {
                const selectedId = e.target.value;

                // Find the selected team
                const selectedTeam = teamList.data.result.find((team) => {
                  const teamIdString = String(team.TeamId); // Convert to string

                  return teamIdString === selectedId;
                });

                setSelectedVisitorTeam(selectedTeam?.TeamName || "");
                setSelectedVisitorTeamId(selectedId);
              }}
              className="input input_select"
            >
              <option value="">Select Visitor Team</option>
              {teamList.data?.result.map((team) => (
                <option key={team.TeamId} value={team.TeamId}>
                  {team.TeamName}
                </option>
              ))}
            </select>
          </div>
          <div className="col-md-12 input_group">
            <label htmlFor="txtScores">Scores</label>
            <div style={{ display: "flex", alignItems: "center" }}>
              <input
                type="number"
                value={VisitorScores}
                className="input"
                placeholder="Visitor Score"
                onChange={(e) => setVisitorScores(e.target.value)}
              />
              <input
                type="number"
                style={{
                  marginBottpm: "-20px",
                  marginLeft: "5px",
                }}
                value={HomeScores}
                className="input"
                placeholder="Home Score"
                onChange={(e) => setHomeScores(e.target.value)}
              />
            </div>
          </div>
          <div className="col-md-6 input_group">
            <label htmlFor="ddlHomeTeamNames">
              Home Team Name <span className="edd-required-indicator">*</span>
            </label>
            <select
              name="ddlHomeTeamNames"
              className="input input_select"
              id="ddlHomeTeamNames"
              type="text"
              value={SelectedHomeTeamId}
              onChange={(e) => {
                const selectedhomeId = e.target.value;

                const selectedHomeTeam = teamList.data.result.find((team) => {
                  const teamIdString = String(team.TeamId); // Convert to string

                  return teamIdString === selectedhomeId;
                });

                setSelectedHomeTeam(selectedHomeTeam?.TeamName || ""); // Set team name
                setSelectedHomeTeamId(selectedhomeId);
              }}
            >
              <option value="">Select Home Team</option>
              {teamList.data?.result.map((team) => (
                <option key={team.TeamId} value={team.TeamId}>
                  {team.TeamName}
                </option>
              ))}
            </select>
          </div>
          <div className="col-md-6 input_group">
            <label htmlFor="txtGameNum">Game Number</label>
            <input
              name="txtGameNum"
              id="txtGameNum"
              className="input"
              type="text"
              value={GameNumber}
              onChange={(e) => setGameNumber(e.target.value)}
            />
          </div>
          <div className="col-md-12 input_group">
            <label htmlFor="playingField">Playing Field</label>
            <div
              style={{
                display: isHidden ? "block" : "flex",
                alignItems: "center",
              }}
            >
              <select
                value={FieldNumber}
                onChange={(e) => setFieldNumber(e.target.value)}
                className="input input_select"
              >
                <option selected="selected" value="0">
                  Select
                </option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
              </select>
              <span style={{ padding: "5%" }}>or</span>
              <input
                type="text"
                value={FieldNumber}
                onChange={(e) => setFieldNumber(e.target.value)}
                className="input"
              />
            </div>
          </div>

          <div className="col-md-12 btn_wrap">
            <button type="submit" className="btn btn_primary w-100">
              Register Now
            </button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default ScheduleReg;
