import React from "react";

import Modal from "react-modal";

const Rules = ({ isOpen, onClose }) => {
  const customStyles = {
    content: {
      width: "80%", // Adjust this value to your desired width
      maxWidth: "1015.2px", // Optional: Set a maximum width
      // Center the modal horizontally
      padding: "20px",
      overflowY: "auto",
      maxHeight: "96%",

      margin: "auto",

      // Add some padding
      // Add other styles as needed
    },
  };
  const currentYear = new Date().getFullYear();
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="Rules Modal"
      className="modal-content orange-scrollbar"
      overlayClassName="modal-overlay"
      style={{ ...customStyles }}
    >
      <div className="rulesProcedures_modal ">
        <div className="modal_body">
          <div className="img_wrap">
            <img src="images/banner-slider-01.png" alt="" />
            <div className="content">
              <h2>{currentYear} ProAm Senior Softball Rules and Procedures</h2>
            </div>
            <div className="rules-close-button">
              <svg
                width="30"
                height="30"
                viewBox="0 0 24 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg "
                onClick={onClose}
              >
                <path
                  d="M12 23.25C6.07 23.25 1.25 18.43 1.25 12.5C1.25 6.57 6.07 1.75 12 1.75C17.93 1.75 22.75 6.57 22.75 12.5C22.75 18.43 17.93 23.25 12 23.25ZM12 3.25C6.9 3.25 2.75 7.4 2.75 12.5C2.75 17.6 6.9 21.75 12 21.75C17.1 21.75 21.25 17.6 21.25 12.5C21.25 7.4 17.1 3.25 12 3.25Z"
                  fill="#1B1B1B"
                />
                <path
                  d="M9.17035 16.08C8.98035 16.08 8.79035 16.01 8.64035 15.86C8.35035 15.57 8.35035 15.09 8.64035 14.8L14.3004 9.13999C14.5904 8.84999 15.0704 8.84999 15.3604 9.13999C15.6504 9.42999 15.6504 9.90998 15.3604 10.2L9.70035 15.86C9.56035 16.01 9.36035 16.08 9.17035 16.08Z"
                  fill="#1B1B1B"
                />
                <path
                  d="M14.8304 16.08C14.6404 16.08 14.4504 16.01 14.3004 15.86L8.64035 10.2C8.35035 9.90998 8.35035 9.42999 8.64035 9.13999C8.93035 8.84999 9.41035 8.84999 9.70035 9.13999L15.3604 14.8C15.6504 15.09 15.6504 15.57 15.3604 15.86C15.2104 16.01 15.0204 16.08 14.8304 16.08Z"
                  fill="#1B1B1B"
                />
              </svg>
            </div>
          </div>
          <div className="content">
            <h3>
              1) Game Rules - Will be same as USA Amateur Softball Except for
              the following.
            </h3>
            <p>
              A. Rosters – Florida Teams will only have players from within the
              state of Florida (exceptions will be granted for Players living in
              bordering states. Will need approval first. Any Roster changes
              will need approval.
              <br />
              B. Eligible Player
              <br />
              40s Teams are allowed 1 League Age 39 and the remainder turning 40
              by December 31st 2025
              <br />
              50s Teams are allowed 1 League Age 49 and the remainder turning 50
              by December 31st 2025
              <br />
              60s Teams are allowed 1 League Age 59 and the remainder turning 50
              by December 31st 2025
              <br />
              65s Teams are allowed 1 League Age 64 and the remainder turning 50
              by December 31st 2025
              <br />
              70s Teams are allowed 3 League Age 69 and the remainder turning 70
              by December 31st 2025
              <br />
              75s Teams are allowed 2League age 73s, 3 League age 74s and the
              remainder turning 75 by December 31st 2025
              <br />
            </p>

            <h3>A. Roster Players</h3>
            <p>
              Total Roster Players shall not exceed 20 Players. A Roster Player
              is registered by the Coach at the beginning of that Team’s Playing
              year. That Team is that Player’s Primary Team for the Year. If
              this Player Plays as a Pickup Player for a different team at a
              tournament that his primary team is not playing in this is the
              player’s Secondary Team. The Player can only play in the State
              Tournament for his Primary Team. Exception: His Primary Team did
              not register for the State Tournament, then the player can play
              for his Secondary Team.
            </p>

            <h3>B. Player Addition</h3>
            <p>
              The Coach can only register One Upper Level Player no more than
              two levels up Example: A C Team can pick up an A Player but no
              Higher. And Four same level of player or Lower.
            </p>

            <h3>C. Pick Up Player</h3>
            <p>
              A Pick Up Player is a Player that is on a Team that did not come
              to the Tournament OR an Available Player that is not on a Team. A
              Player cannot be Picked Up If his Team is competing in the
              Tournament. Exception: Coach from his primary team permits his
              Player to Play for another Team for the entire Tournament. That
              Player cannot also Play for his Primary Team.
            </p>
            {/* <h3>D. Player Addition</h3>
            <p>
              The Coach can only register One Upper Level Player no more than
              two levels up Example: A C Team can pick up an A Player but no
              Higher. And Four same level of player or Lower.
            </p>
            <h3>E. Pick Up Player</h3>
            <p>
              A Pick Up Player is a Player that is on a Team that did not come
              to the Tournament OR an Available Player that is not on a Team. A
              Player cannot be Picked Up If his Team is competing in the
              Tournament. Exception: Coach from his primary team permits his
              Player to Play for another Team for the entire Tournament. That
              Player cannot also Play for his Primary Team. He Can Play for a
              50s Team and a 60s if it helps bring in new Teams. But is Strongly
              urged not to.
            </p> */}
            <h3>D. Available Player / Free Agent</h3>
            <p>
              Available Player is a Pick Up Player unless he is not registered
              with a Team. Then he is a Free Agent and can either stay on the
              Pick Up Team or not and be available for a different Team in
              future Tournaments.
            </p>
            <h3>E. Major Plus Division</h3>
            <p>
              The homerun limit will be 9 with 1 up unless otherwise specified
              per Tournament. Teams can score a maximum of seven (7) runs per
              inning until the last inning which will be an open inning. Teams
              will hit the ProAm Softball approved Softball.
            </p>
            <h3>F. Major Division</h3>
            <p>
              The homerun limit will be 9 with 1 up unless otherwise specified
              per Tournament. Teams can score a maximum of seven (7) runs per
              inning until the last inning which will be an open inning. Teams
              will hit the ProAm Softball approved Softball.
            </p>
            <h3>G. A Division</h3>
            <p>
              The homerun limit will be 9 with 1 up unless otherwise specified
              per Tournament. Teams can score a maximum of seven (7) runs per
              inning until the last inning which will be an open inning. Teams
              will hit the ProAm Softball approved Softball.
            </p>
            <h3>H. B Division</h3>
            <p>
              The homerun limit will be 9 with 1 up unless otherwise specified
              per Tournament. Teams can score a maximum of seven (7) runs per
              inning until the last inning which will be an open inning. Teams
              will hit the ProAm Softball approved Softball.
            </p>
            <h3>I. C Division</h3>
            <p>
              The homerun limit will be 9 with 1 up unless otherwise specified
              per Tournament. Teams can score a maximum of seven (7) runs per
              inning until the last inning which will be an open inning. Teams
              will hit the ProAm Softball approved Softball.
            </p>
            <h3>J. D Division</h3>
            <p>
              The homerun limit will be 1 with 1 up unless otherwise specified
              per Tournament. Teams can score a maximum of seven (7) runs per
              inning until the last inning which will be an open inning. Teams
              will hit the ProAm Softball approved Softball.
            </p>
            <h3>K. A Mixed Division Bracket</h3>
            <p>
              Lower Division Team has the choice of an Eleventh Player on the
              field or five runs. There may be a Home Run Limit. When the Limit
              is reached it may either be one up, or an out, or a single base
              hit, this will be decided per Tournament.
            </p>
            <h3>L. ProAm Softball Approved Softballs</h3>
            <p>
              ProAm approved softballs will be provided at the Tournaments. If
              the Coach has ProAm approved Softballs from a previous Tournament
              he may use them. Exception: Softballs with small Fence or Road
              nicks in them may be used with Umpire Approval.
            </p>
            <h3>M. Pre Game Meeting</h3>
            <p>
              Coaches need to meet with the Umpire before each game at Home
              Plate. Umpire and Coaches will introduce themselves and go over a
              few rules and answer any questions the Coaches may have.
            </p>
            <h3>N. Coach Protests</h3>
            <p>
              If Coaches have any Protest, Questions, or Concerns about the
              Game. Either come to the Reception Table or Email:
              proamsoftball@gmail.com
            </p>
            <h3>O. A Homerun over the Fence</h3>
            <p>
              Is considered Hit and sit. All Base runners do not have to touch
              another base.
            </p>
            <h3>P. Pitch Count</h3>
            <p>
              Beginning Pitch Count is 1 Ball and 1 Strike no Courtesy Foul
              After Second Strike.
            </p>
            <h3>Q. Pitching Position</h3>
            <p>Pitcher has to pitch from the mound.</p>
            <h3>R. Pitching Heights</h3>
            <p>Pitching Heights are Six to 10 Feet.</p>
            <h3>S. Pitching Motion</h3>
            <p>
              One continuous motion while Facing and looking forward. Nothing
              Fancy.
            </p>
            <h3>T. Pitcher Safety Net</h3>
            <p>
              Pitcher must be behind net when ball is struck. On a hard hit
              ball, pitcher may not come from behind the net until it is safe to
              react to a ball that has hit the ground. If the ball is a line
              drive he may reach out to catch it with his glove, but his head
              and body needs to remain behind the safety net.
            </p>
            <h3>U. Pitching Net Position</h3>
            <p>
              Pitching Net cannot be more than 3 feet in front of Pitching
              Mound. Once set cannot be moved during the half inning unless
              relief Pitcher is put in. Cannot cover more than half the Pitching
              Mound.
            </p>
            <h3>V. Tie Breaker</h3>
            <p>
              First Tie Breaking Inning for As or higher 9 runs, Bs 7 runs, Cs
              and Ds 5 runs last out starts on Second base. Exception: Second
              base Runner can be substituted with runner after last out base
              runner touches second. Second Tie Breaking Inning last out starts
              on Third Base and Previous Batter on Second Base with runs same as
              above.
            </p>
            <h3>W. Time Limit for Games</h3>
            <p>
              60 Minutes expires, finish that inning, and play one more.
              EXCEPTION: If the timer goes off before first pitch of top of
              inning, then that will be open inning.
            </p>
            <h3>X. Runner Substitution</h3>
            <p>
              Runner Substitutes need to be ready to run as soon as Batter
              reaches his base. If Batter gets up and on Base a second time in
              same inning, he may use the same runner that last ran for him. A
              substitute runner cannot run for a second base runner in the same
              inning.
            </p>
            <h3>Y. DETERMINING WINNING TEAM</h3>
            <p>
              If Win/Loss is same. It goes to Head to Head. If the two teams
              played each other twice and both have won once then It goes to
              Total Runs given up, Then Runs Scored, Then Runs Given up Head to
              Head.
            </p>
          </div>
          <div className="content">
            <h3>2) Uniforms </h3>
            <p>
              Players should have matching uniforms Shirts. Coaches should have
              similar Colors. ProAm director may make decisions for first time
              exceptions to uniform use.
            </p>

            <h3>3) Line Ups</h3>
            <p>
              Coaches need to provide Line ups to the Scorekeeper and to the
              competing Team Coach ten minutes before game time.
            </p>
            <h3>4) Game Times</h3>
            <p>
              Games will begin on time. After First game of the Day, the
              following Games may begin 30 Minutes Early.
            </p>
            <h3>5) Bench Area/Dugout</h3>
            <p>Only Coaches, Players, and Sponsors are allowed in dugout.</p>
            <h3>6) Players Outside of Dugout</h3>
            <p>
              Only Batter, On Deck Batter, and Base Coaches are aloud outside of
              Dugout while Game is in Play.
            </p>
            <h3>7) Offensive Language</h3>
            <p>
              No Spectators, Players, or Coaches are allowed to use offensive
              language or remarks towards anyone. In the interest of SAFETY the
              Umpire will give one warning to the perpetrator. Coaches and their
              Players are expected to have Good Sportsmanship behavior
            </p>
            <h3>8) Ejection</h3>
            <p>
              For Offensive Language That Game and One More, For Pushing or
              Striking Another Player The Rest of That Tournament and after
              reviewing the circumstances, Possibly Further Action.
            </p>
            <h3>9) Run Rule</h3>
            <p>
              40s, 50s, 60s, 65s, 70s, and 74s Division: 20 Runs after 4
              Innings, 15 Runs after 5 Innings.
            </p>
            {/* <h3> 10) Inclement Weather</h3>
            <p>
              If there is a 50% chance of rain in the morning or afternoon games
              will play regular pitch count, 7 runs per inning for first 3
              innings, then the innings are open. After 45 minutes are up the
              Umpire will say this is the last inning.
            </p> */}
            <h3>
              10) If Games Rained out Saturday will continue Sunday with 45
              Minutes plus open Inning. The remaining, if any, games from
              Saturday will begin Sunday until completed then Saturday Schedule
              will follow.
            </h3>

            <h3>
              11) Rain Delay -{" "}
              <p>
                In the event of rain there may be a one- or two-hour delay of
                scheduled games. It is the manager's responsibility to contact
                the Tournament Representative at the field for rescheduled game
                information. In the event the tournament can't be completed
                within the scheduled time frame and more than forty percent
                (40%) of the scheduled games have been completed, the official
                final order of finish will be determined by the position of the
                teams in the bracket. The following procedures will be used to
                break ties and among teams in the same spot in the bracket:
              </p>
            </h3>
            <p>1. Win-loss records except among undefeated teams.</p>
            <p>
              2. If only two teams are tied for a position and have played each
              other, the winner of that game.
            </p>
            <p>
              3. Exception: If Teams played each other twice then it will be
              determined by Total Runs Given up or Runs Against in all games.
            </p>
            <p>
              4. If 3 or more teams are tied in a bracket it will be ranked
              according to the fewest runs allowed from all games played.
            </p>
            <p>
              5. If tied on fewest runs allowed, then total runs scored wins
            </p>
            <p>
              6. If still tied, runs given up in head to head games. Or Runs
              scored Head to Head.
            </p>
            <p>7. If a tie still exists, by a coin toss</p>
            <p>
              WE WILL DO OUR BEST TO COMPLETE THE TOURNAMENT EVEN IF WE MUST
              PLAY AROUND THE CLOCK AND UNDER ADVERSE WEATHER CONDITIONS. THE
              TOURNAMENT FORMAT WILL BE MODIFIED IF NECESSARY TO COMPLETE THE
              GAMES AND CROWN THE TOURNAMENT CHAMPIONS. TEAMS SHOULD REPORT TO
              THEIR SCHEDULED FIELD FOR RAIN DELAY INSTRUCTIONS. IN THE EVENT OF
              PROLONGED RAIN PLEASE CONTACT A TOURNAMENT OFFICIAL FOR FURTHER
              INSTRUCTIONS. SHOULD AN EVENT BE TOTALLY RAINED OUT THE PROAM
              SOFTBALL WILL WORK WITH THE PARTICIPATING TEAMS TO RESCHEDULE
              AND/OR PROVIDE AN APPROPRIATE CREDIT TOWARD ANOTHER FUTURE
              TOURNAMENT.
            </p>
            {/* <h3>13. SPECIAL DIVISION RULES</h3>
            <p>
              In the 65+ men’s divisions and up and 50+ to 70+ women’s divisions
              eleven defensive players shall constitute a team (Teams may elect
              to start and play with 10 defensive players with no penalty). ASA
              rules for re-entry and extra players will apply. In the 70+
              Division the roster may include up to three 69 year old players.
              In the 75+ Division the roster may include up to five 74 year old
              players. And in the 80+ Division the roster may include up to
              seven 78 and/or 79 year old players. Roster eligibility for 70+,
              75+ and 80+ men's teams needs to live in the state of Florida.
            </p> */}
            <h3>12. CONDUCT</h3>
            <p>
              Team Managers and coaches are asked to have complete control of
              their players both on and off the playing field. Any report of
              damage by hotel and/or park management will be dealt with by the
              tournament committee. Managers are reminded that an ejected player
              must have a substitute or the game is a forfeit.
            </p>

            <h3>13. ELIGIBILITY</h3>
            <p>
              Participation in PROAM SOFTBALL is open to amateur players only. A
              player may only play with one team per Age Division. A player on
              two rosters in different age classifications must be at the same
              skill level unless approved otherwise by the tournament director.
              All teams should refer to the ASA code and check with the
              Tournament officials for clarifications and interpretations. The
              ProAm SOFTBALL has established residency requirements for team
              rosters in all divisions. Unless approved otherwise in writing,
              all roistered players must reside in the state of Florida or
              adjacent state. Teams must play with the same roster that was used
              to determine their classification by the PROAM SOFTBALL Rating
              Committee. EXCEPTION: A team may use up to three pick-up players
              by advising the PROAM SOFTBALL office prior to the tournament. The
              pick-up players must come from a team(s) in the same rated
              division. The use of any other pickup players must be approved in
              writing by the tournament staff prior to the tournament and may
              result in a higher team classification. A complete and accurate
              player softball history must accompany any request to use pickup
              players. All players must be on the roster prior to the start of
              the tournament; under no circumstances will teams be allowed to
              pick up players at the tournament. Non-rated teams must submit
              their roster in advance for approval and team classification.
            </p>
            <h3> 14. IDENTIFICATION CHECK</h3>
            <p>
              All players will be required to check with the tournament
              committee and produce proof of identity with a Legal picture ID
              Drivers License preferred. Players are required to carry their ID
              at all times since random ID checks may be made prior to any game.
              It is the team manager’s responsibility to verify the eligibility
              of all players on the team. Player eligibility is never waived or
              approved by virtue of the ID check-in at the tournament site.
            </p>
            <h3>15. BATS</h3>
            <p>
              All bats used in ProAm SOFTBALL play for all divisions must be
              official softball bats. Senior Softball Bats are acceptable. Bats
              must have their original paint markings for complete
              identification. Tournament officials have the right to keep any
              bat suspected of being altered or otherwise illegal for a period
              of thirty (30) days to have it inspected and/or tested. Any bat
              found to be altered or illegal could result in the player and
              manager being disqualified from future participation in PROAM 50
              Softball. Any team protesting a bat alleging it to be altered must
              post the Bat Protest Fee of $300.00.
            </p>
            <h3> 16. PROTECTIVE GEAR</h3>
            <p>
              The current bats first pose a clear competitive imbalance issue
              and to a much lesser extent a risk of injury issue. The use of too
              soft bats by some players and not others is an unfair advantage
              which PROAM SOFTBALL believes must be removed from the game. In
              addition, it has been argued that such bats may increase the risk
              of injury from a batted ball. Even with the current composite bats
              in play during recent years, it remains the statistical and
              insurance experience that the game of softball remains among the
              safest athletic activities in America. However, this does not mean
              there is no risk of injury from a batted ball. There is, and
              always has been, risk inherent in the game of senior softball from
              being struck with a batted (or even thrown) ball, a risk of injury
              from a batted (or thrown) ball does exist. Anyone who has a
              concern about an injury occurring from being struck with a ball
              during a ProAm 50 Senior Softball organized tournament should
              address that concern by the use of protective gear which is
              available and has always been allowed in PROAM SOFTBALL play.
            </p>
            <h3>17. BALLS</h3>
            <p>
              All balls used in the PROAM SOFTBALL senior tournaments are PROAM
              SOFTBALL approved-COR44, 375 psi Compression.
            </p>
            <h3> 18. SOUVENIRS</h3>
            <p>
              Souvenir T-shirts will be available throughout the weekend at some
              venues.
            </p>
            <h3>19. ALCOHOLIC BEVERAGES/PETS</h3>
            <p>
              NO ALCOHOLIC BEVERAGES may be brought into most of the parks. Park
              Rangers often monitor the parks and any player(s) caught with
              alcoholic beverages may be disallowed in the park system for the
              remainder of the tournament. Pets are not allowed to run loose in
              most parks. Participants should verify park rules at each venue
              and comply.
            </p>
            <h3> 20. ENTRY FEE REFUNDS</h3>
            <p>
              Entry fees are non-refundable. Teams that withdraw after paying
              the entry fee and more than 30 days prior to the start of the
              tournament may be given a credit toward a future ProAm SOFTBALL
              tournament in the same calendar year. Written requests for a
              partial refund for withdrawal with cause may be considered.
            </p>
            <h3> 21. TOURNAMENT VENDORS</h3>
            <p>
              At most PROAM SOFTBALL senior tournaments we will permit
              individuals and businesses (Vendors) to promote and market their
              products at the park (fields and parking lot). All Vendors are
              required to obtain approval in writing from PROAM SOFTBALL. It is
              the Vendor’s responsibility to fully comply with all local laws,
              codes and ordinances governing the business activity. Anyone
              interested in promoting or selling their product(s) should contact
              the PROAM SOFTBALL Office prior to the event. This Tournament Rule
              applies to all players and teams as well as business interests and
              failure to comply could result in removal from the event.
            </p>
            <h3>22. ENTRY DEADLINE</h3>
            <p>
              The Deadline for entering each PROAM SOFTBALL senior tournament is
              two weeks before the posted date of event. All participating teams
              should submit their Entry Fee online or mail to the PROAM SOFTBALL
              Office by the "Deadline" date. Also, the Online Roster / Player
              management needs to be completed by Entry Deadline. The Team
              Roster needs to be edited on line or printed and filled in and
              mailed in to PROAM SOFTBALL office. However, some tournaments may
              reach the field capacity much sooner than the published Deadline
              in which case the event will be closed at that time, the Online
              Entry Form taken down and thereafter any team desiring to play
              must submit an email requesting to be placed on the waiting list.
              Any team that fails to pay the entry fee prior to the deadline
              date will then be placed on the "Waiting List" with other teams.
            </p>
            <h3>
              {" "}
              23. STATE TOURNAMENT
              <p>
                For {currentYear}, Teams must Register and compete in 4/FOUR
                ProAm Tournaments. The Player must have played for the Team in
                3/THREE Tournaments. If the State Tournament is rained out
                Saturday, then Sunday will be 2 game seeding and the third game
                will be the championship Game.
              </p>
            </h3>

            <p>
              {" "}
              The following procedures will be used to break ties and among
              teams in the same spot in the bracket:
            </p>

            <p>1. Win-loss records except among undefeated teams.</p>

            <p>
              2. If only two teams are tied for a position and have played each
              other, the winner of that game.
            </p>

            <p>
              3. Exception: If Teams played each other twice then it will be
              determined by Total Runs Given up or Runs Against in all games.
            </p>

            <p>
              4. If 3 or more teams are tied in a bracket it will be ranked
              according to the fewest runs allowed from all games played.
            </p>

            <p>
              5. If tied on fewest runs allowed, then total runs scored wins
            </p>

            <p>
              6. If still tied, runs given up in head to head games. Or Runs
              scored Head to Head.
            </p>

            <p>7. If a tie still exists, by a coin toss.</p>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export { Rules };
