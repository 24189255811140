import React, { useState, useRef } from "react";
import axios from "axios";
import Modal from "react-modal";
import DatePicker from "react-datepicker";
import ReactQuill from "react-quill";
import "react-datepicker/dist/react-datepicker.css";

import { API_URLS, BASE_URL } from "../../../../Utils";

const RemLegendAdd = ({ isOpen, onClose, onAdd }) => {
  const [FirstName, setFirstName] = useState("");
  const [LastName, setLastName] = useState("");
  const [DiedOn, setDiedOn] = useState(null);
  const [ContributionInSports, setContributionInSports] = useState("");
  const [isRegisterDisabled, setIsRegisterDisabled] = useState(false);
  const [Photo, setPhoto] = useState("");
  const [imageName, setImageName] = useState("");

  const fileInputRef = useRef(null);

  const formData = {
    FirstName,
    LastName,
    DiedOn: DiedOn ? DiedOn.toISOString() : "",
    ContributionInSports,
    LegendImage: Photo,
  };

  const handleButtonClick = (e) => {
    e.preventDefault();
    fileInputRef.current.click();
  };

  const handleImageChange = async (e) => {
    setIsRegisterDisabled(true);
    setTimeout(() => {
      setIsRegisterDisabled(false);
    }, 5000);
    try {
      if (e.target.files && e.target.files[0]) {
        const file = e.target.files[0];
        const reader = new FileReader();
        reader.onload = (upload) => {
          setImageName(upload.target.result);
        };
        reader.readAsDataURL(file);

        let data = new FormData();
        data.append("legendImage", file);
        const response = await axios.post(API_URLS.UPLOAD_LEGEND_IMAGE, data);

        if (response.data.data.image.filename) {
          setPhoto(response.data.data.image.filename); // Store filename or URL
        }
      }
    } catch (error) {
      console.error("Error uploading image:", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!FirstName.trim()) {
      alert("Please Enter The First Name.");
      return;
    }
    if (!LastName.trim()) {
      alert("Please Enter The Last Name.");
      return;
    }
    if (!DiedOn || !(DiedOn instanceof Date) || isNaN(DiedOn.getTime())) {
      alert("Please Enter The Date.");
      return;
    }
    if (!ContributionInSports.trim()) {
      alert("Please Enter Contribution In Sports.");
      return;
    }

    if (!Photo.trim()) {
      alert("Please Upload The Photo.");
      return;
    }

    try {
      const response = await axios.post(API_URLS.ADD_LEGEND, formData, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (response.status === 200) {
        alert("Legend Added Successfully");
        onClose();
        onAdd();
      } else {
        alert("An error Occurred");
      }
    } catch (error) {
      console.error("Error:", error);
      alert("An error occurred while processing the request.");
    }
  };

  const customStyles = {
    content: {
      width: "80%",
      padding: "20px",
      overflowY: "auto",
      maxHeight: "96%",
      margin: "auto",
    },
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="Add Legend Modal"
      className="modal-content orange-scrollbar"
      overlayClassName="modal-overlay"
      style={{ ...customStyles }}
    >
      <div className="form_wrap teamReg_form">
        <div className="close-button">
          <h3>Add Legend</h3>
          <svg
            width="30"
            height="30"
            viewBox="0 0 24 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            onClick={onClose}
          >
            <path
              d="M12 23.25C6.07 23.25 1.25 18.43 1.25 12.5C1.25 6.57 6.07 1.75 12 1.75C17.93 1.75 22.75 6.57 22.75 12.5C22.75 18.43 17.93 23.25 12 23.25ZM12 3.25C6.9 3.25 2.75 7.4 2.75 12.5C2.75 17.6 6.9 21.75 12 21.75C17.1 21.75 21.25 17.6 21.25 12.5C21.25 7.4 17.1 3.25 12 3.25Z"
              fill="#1B1B1B"
            />
            <path
              d="M9.17035 16.08C8.98035 16.08 8.79035 16.01 8.64035 15.86C8.35035 15.57 8.35035 15.09 8.64035 14.8L14.3004 9.13999C14.5904 8.84999 15.0704 8.84999 15.3604 9.13999C15.6504 9.42999 15.6504 9.90998 15.3604 10.2L9.70035 15.86C9.56035 16.01 9.36035 16.08 9.17035 16.08Z"
              fill="#1B1B1B"
            />
            <path
              d="M14.8304 16.08C14.6404 16.08 14.4504 16.01 14.3004 15.86L8.64035 10.2C8.35035 9.90998 8.35035 9.42999 8.64035 9.13999C8.93035 8.84999 9.41035 8.84999 9.70035 9.13999L15.3604 14.8C15.6504 15.09 15.6504 15.57 15.3604 15.86C15.2104 16.01 15.0204 16.08 14.8304 16.08Z"
              fill="#1B1B1B"
            />
          </svg>
        </div>
        <form
          onSubmit={handleSubmit}
          className="row"
          style={{ marginRight: "10px", marginLeft: "1px" }}
        >
          <div className="col-md-6 input_group">
            <label htmlFor="firstName">
              First Name<span className="edd-required-indicator">*</span>
            </label>
            <input
              name="firstName"
              id="firstName"
              className="input"
              type="text"
              value={FirstName}
              onChange={(e) => setFirstName(e.target.value)}
            />
          </div>
          <div className="col-md-6 input_group">
            <label htmlFor="lastName">
              Last Name<span className="edd-required-indicator">*</span>
            </label>
            <input
              name="lastName"
              id="lastName"
              className="input"
              type="text"
              value={LastName}
              onChange={(e) => setLastName(e.target.value)}
            />
          </div>
          <div className="col-md-6 input_group">
            <label htmlFor="DiedOn">
              Died On <span className="edd-required-indicator">*</span>
            </label>
            <DatePicker
              className="input"
              placeholderText="Select Date"
              dateFormat="MM/dd/yyyy"
              selected={DiedOn}
              onChange={(date) => setDiedOn(date)}
            />
          </div>
          <div className="col-md-12 input_group">
            <label htmlFor="ContributionInSports">
              Contribution In Sports
              <span className="edd-required-indicator">*</span>
            </label>
            <ReactQuill
              name="ContributionInSports"
              id="ContributionInSports"
              value={ContributionInSports}
              onChange={(value) => setContributionInSports(value)} // ReactQuill provides the value directly
              style={{
                borderRadius: 8,
                height: "200px",
                marginBottom: "50px",
                width: "100%",
              }}
            />
          </div>

          <div className="col-md-12 input_group">
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <label htmlFor="uploadPhoto">
                Upload Legend Image{" "}
                <span className="edd-required-indicator">*</span>
              </label>
              <button
                className="btn btn_primary w-20"
                onClick={handleButtonClick}
              >
                Choose File
              </button>
            </div>
            <input
              type="file"
              ref={fileInputRef}
              style={{ display: "none" }}
              onChange={handleImageChange}
            />
            {(Photo || imageName) && (
              <img
                src={
                  Photo
                    ? `${BASE_URL}/api/v1/files/LegendImage/${Photo}`
                    : imageName
                }
                alt="Legend Photo"
                style={{ maxWidth: "150px" }}
              />
            )}
          </div>

          <div className="col-md-12 btn_wrap">
            <button
              type="submit"
              className="btn btn_primary w-100"
              disabled={isRegisterDisabled}
            >
              {isRegisterDisabled ? "Wait to Upload the Image" : "Add Legend"}
            </button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default RemLegendAdd;
