import React, { useState, useRef, useEffect } from "react";
import Modal from "react-modal";
import Slider from "react-slick";
import swal from "sweetalert";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import axios from "axios";
import Select from "react-select";

import { API_URLS, BASE_URL } from "../../../Utils";

const formatDate = (dateString) => {
  const options = { day: "numeric", month: "long", year: "numeric" };
  return new Date(dateString).toLocaleDateString("en-US", options);
};

const PlayerProf = ({ isOpen, onClose }) => {
  const fileInputRef = useRef(null);
  const fileInputRefPlayer = useRef(null);
  const handleButtonClick = (e) => {
    e.preventDefault();
    fileInputRef.current.click();
  };
  const handleButtonClickPlayer = (e) => {
    e.preventDefault();
    fileInputRefPlayer.current.click();
  };
  //  / console.log("Hola In PlayerReg");
  const PlayerId = window.sessionStorage.getItem("PlayerId");
  const [bracketWinner, setBracketWinner] = useState([]);
  const [imageName, setImageName] = useState("");
  const [isFreeAgent, setIsFreeAgent] = useState(false);
  const [teamListData, setTeamListData] = useState([]);
  const [options, setOptions] = useState([]);
  const [defaultSelectedOptions, setDefaultSelectedOptions] = useState([]);
  const [isRegisterDisabled, setIsRegisterDisabled] = useState(false);
  const [playerTeamIds, setPlayerTeamIds] = useState([]);
  const [playerData, setPlayerData] = useState([]);
  const [previouslyDeletedOptions, setPreviouslyDeletedOptions] = useState([]);
  const [teamName, setTeamName] = useState();
  const [newAddedTeam, setNewAddedTeam] = useState([]);
  const [formData, setFormData] = useState({
    FirstName: "",
    LastName: "",
    EmailId: "",
    Password: "",
    PlayerId: "",
    PlayerNumber: "",
    isAnonymous: isFreeAgent,
    PlayerStatistics: "",
    UniqueNum: "",
    Bating: "",
    TeamId: [],
    DOB: "",
    State: "",
    Bats: "",
    Throws: "",
    Position: "",
    Address: "",
    City: "",
    PhoneNumber: "",
    Comments: "",
    PlayerPhoto: "",
    License: "",
    PlayLevel: "",
    isAvailable: false,
  });
  const settings = {
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    arrows: false,
    dots: true,
    fade: true,
  };

  // console.log("newadeed", newAddedTeam);
  useEffect(() => {
    // Format options for Select component

    const formattedOptions = [
      { value: "choose-team", label: "Choose Team From Drop Down" },
      // { value: "free-agent", label: "Register Yourself as A Free Agent" },
      ...teamListData.map((team) => ({
        value: team.TeamName,
        label: team.TeamName,
      })),
    ];
    setOptions(formattedOptions);

    // Format defaultSelectedOptions
    const selectedOptions = formattedOptions.filter((option) =>
      playerTeamIds.includes(
        teamListData.find((team) => team.TeamName === option.value)?.TeamId
      )
    );

    setDefaultSelectedOptions(selectedOptions);
  }, [teamListData, playerTeamIds]);

  const handleselectChanges = (selectedOptions) => {
    handleSelectTeamChange(selectedOptions);
  };

  const handleSelectTeamChange = (selectedOptions) => {
    // Find current selected values
    const selectedValues = selectedOptions.map((option) => option.value);

    // Find previously selected values (previous state)
    const previousSelectedValues = defaultSelectedOptions.map(
      (option) => option.value
    );

    // Determine newly added options
    const addedOptions = selectedValues.filter(
      (value) => !previousSelectedValues.includes(value)
    );

    // Map added options to TeamIds
    const addedTeamIds = addedOptions.map((value) => {
      const addedTeam = teamListData.find((team) => team.TeamName === value);
      return addedTeam ? addedTeam.TeamId : 0;
    });

    // Update newAddedTeam state with the newly added team IDs
    setNewAddedTeam((prevAddedTeams) => [...prevAddedTeams, ...addedTeamIds]);

    // Determine deleted options
    const deletedOptions = previousSelectedValues.filter(
      (value) => !selectedValues.includes(value)
    );

    // Map deleted options to TeamIds
    const deletedTeamIds = deletedOptions.map((value) => {
      const deletedTeam = teamListData.find((team) => team.TeamName === value);
      return deletedTeam ? deletedTeam.TeamId : 0;
    });

    // Update the previously deleted options state by accumulating new IDs
    setPreviouslyDeletedOptions((prevDeletedOptions) => {
      const newDeletedOptions = new Set([
        ...prevDeletedOptions,
        ...deletedTeamIds,
      ]);
      return Array.from(newDeletedOptions);
    });

    // Determine if the user is a free agent
    const isFreeAgent = selectedValues.includes("free-agent") ? 1 : 0;

    // Determine the new team IDs
    const teamIds = selectedValues.map((value) => {
      const selectedTeam = teamListData.find((team) => team.TeamName === value);
      setTeamName(selectedTeam.TeamName);
      return selectedTeam ? selectedTeam.TeamId : 0;
    });

    // Update the form data and free agent state
    setIsFreeAgent(isFreeAgent);
    setFormData((prevState) => ({
      ...prevState,
      isAnonymous: isFreeAgent,
      TeamId: teamIds,
    }));

    // Update the defaultSelectedOptions state
    setDefaultSelectedOptions(selectedOptions);
  };

  const handleChange = (e) => {
    const { name, type, checked, value } = e.target;

    if (type === "checkbox") {
      // Handle checkbox change
      setFormData((prevData) => ({
        ...prevData,
        [name]: checked, // Set true if checked, false if unchecked
      }));
    } else if (name === "PlayLevel") {
      // Parse PlayLevel as an integer
      const updatedValue = parseInt(value, 10);
      setFormData((prevState) => ({
        ...prevState,
        [name]: updatedValue,
      }));
    } else if (name === "ddlState") {
      // Handle dropdown for state
      setFormData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    } else {
      // Handle other input types
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const {
      FirstName,
      LastName,
      EmailId,
      Password,
      PlayerNumber,
      State,
      City,
      Address,
      PhoneNumber,
      PlayerPhoto,
      License,
      isAnonymous,
      PlayLevel,
      isAvailable,
    } = formData;

    // Check for empty fields
    if (!FirstName.trim()) {
      swal("Please Enter First Name.", "", "info");
      return;
    }
    if (!LastName.trim()) {
      swal("Please Enter Last Name.", "", "info");
      return;
    }
    if (!EmailId.trim()) {
      swal("Please Enter Email ID.", "", "info");
      return;
    }
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    if (!emailPattern.test(EmailId.trim())) {
      swal("Please Enter a valid Email ID.", "", "info");
      return;
    }
    if (!Password.trim()) {
      swal("Please Enter Password.", "", "info");
      return;
    }

    if (PlayLevel === 0 || PlayLevel === null || PlayLevel === "0") {
      swal("Please select the Level of Play for the League.", "", "info");
      return;
    }
    const playerNumberStr = String(PlayerNumber).trim();
    if (!playerNumberStr) {
      swal("Please Enter Jersey Number.", "", "info");
      return;
    }
    try {
      for (const teamId of previouslyDeletedOptions) {
        const url = `${BASE_URL}/api/v1/web/player/DeletePlayer?PlayerId=${PlayerId}&TeamId=${teamId}`;
        const deleteResponse = await fetch(url, {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
          },
        });

        if (!deleteResponse.ok) {
          swal(`Failed to delete team with ID ${teamId}.`, "", "error");
          return; // Stop further processing if any delete fails
        }
      }

      if (newAddedTeam.length > 0) {
        for (const teamId of newAddedTeam) {
          const url = `${BASE_URL}/api/v1/web/player/add_existing_player`;
          const addResponse = await fetch(url, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              PlayerId,
              TeamId: teamId,
            }),
          });

          if (!addResponse.ok) {
            swal(`Failed to add team with ID ${teamId}.`, "", "error");
            return; // Stop further processing if any add fails
          }
        }
      }

      const updatedFormData = {
        ...formData,
        TeamId: 0,
      };

      // console.log("Here is daaTa", updatedFormData);
      // return;
      const url = API_URLS.UPDATE_Player;
      const response = await fetch(url, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(updatedFormData),
      });

      if (response.ok) {
        const data = await response.json();

        if (data && data.status === "success") {
          swal("Player Updated Successfully");
        } else {
          swal("Error Updating Player. Try Again");
        }
      } else {
        swal("Server Error: Unable to process the request.");
      }
    } catch (error) {
      console.error("Error:", error);
      swal("An error occurred while processing the request.");
    }
  };
  const customStyles = {
    content: {
      width: "80%",
      maxWidth: "1015.2px",
      padding: "20px",
      overflowY: "auto",
      maxHeight: "96%",

      margin: "auto",
    },
  };

  const handleImageChange = async (e) => {
    setIsRegisterDisabled(true);
    setTimeout(() => {
      setIsRegisterDisabled(false);
    }, 5000);
    try {
      if (e.target.files && e.target.files[0]) {
        const file = e.target.files[0];
        let data = new FormData();
        data.append("playerImage", file);
        const response = await axios.post(API_URLS.UPLOAD_PLAYER_IMAGE, data);

        if (response.data.data.image.filename) {
          setFormData((prevState) => ({
            ...prevState,
            PlayerPhoto: response.data.data.image.filename,
          }));
        }
      }
    } catch (error) {
      console.error("Error uploading image:", error);
    }
  };

  const handleLicenseImageChange = async (e) => {
    setIsRegisterDisabled(true);
    setTimeout(() => {
      setIsRegisterDisabled(false);
    }, 5000);
    try {
      if (e.target.files && e.target.files[0]) {
        const file = e.target.files[0];
        let data = new FormData();
        data.append("licenseImage", file);
        const response = await axios.post(API_URLS.UPLOAD_LICENSE_IMAGE, data);

        if (response.data.data.image.filename) {
          setFormData((prevState) => ({
            ...prevState,
            License: response.data.data.image.filename,
          }));
        }
      }
    } catch (error) {
      console.error("Error uploading license image:", error);
    }
  };

  const getPlayerById = () => {
    const params = new URLSearchParams({
      SelectedPlayerId: PlayerId,
      SelectedTeamId: 0,
    }).toString();

    const url = `${API_URLS.GET_PLAYERS_BY_PLAYER_ID}?${params}`;

    fetch(url)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok.");
        }
        return response.json();
      })
      .then((data) => {
        // console.log("Fetched data:", data); // Log the entire response

        // Store the full array of results
        const results = data.data.result;
        setPlayerData(results);

        // Log the player data to verify
        // console.log("Unique data:", results);
        if (results.length > 0) {
          const teamIdsFromResult = data.data.result.map((item) => item.TeamId);

          const player = results[0]; // Assuming you're interested in the first player
          //console.log("Player data:", player);

          setFormData({
            FirstName: player.FirstName || "",
            LastName: player.LastName || "",
            EmailId: player.EmailId || "",
            Password: player.Password || "",
            PlayerId: player.PlayerId || "",
            PlayerNumber: player.PlayerNumber || "",
            PlayerStatistics: player.PlayerStatistics || "",
            UniqueNum: player.UniqueNumber || "",
            isAnonymous:
              player.isAnonymous !== undefined
                ? player.isAnonymous
                : formData.isAnonymous,
            Bating: player.Bating || "",
            TeamId: teamIdsFromResult || [],
            DOB: player.DOB || "",
            State: player.State || "",
            Bats: player.Bats || "",
            Throws: player.Throws || "",
            Position: player.Position || "",
            Address: player.Address || "",
            City: player.City || "",
            PhoneNumber: player.PhoneNumber || "",
            Comments: player.Comments || "",
            PlayerPhoto: player.PlayerPhoto || "",
            License: player.License || "",
            PlayLevel: player.PlayLevel || "",
            isAvailable: player.isAvailable || false,
          });
        }
        // console.log("Form data:", formData);

        // Extract and store the team IDs from each result
        const teamIdsFromResult = results.map((item) => item.TeamId);
        setPlayerTeamIds(teamIdsFromResult);

        // Log the team IDs to verify
        //console.log("Extracted team IDs:", teamIdsFromResult);
      })
      .catch((error) => {
        console.error("Fetch error:", error.message);
      });
  };
  const getAllTeams = () => {
    const url = API_URLS.GET_ALL_TEAMS;
    fetch(url)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok.");
        }
        return response.json();
      })
      .then((data) => {
        if (
          data &&
          data.data &&
          Array.isArray(data.data.result) &&
          Array.isArray(data.data.result[0])
        ) {
          //console.log("teamList", data.data.result[0]);
          setTeamListData(data.data.result[0]);
        } else {
          swal("Unexpected data format:", data);
          throw new Error("Unexpected data format.");
        }
      })
      .catch((error) => {
        console.error("Fetch error:", error.message);
      });
  };

  const fetchLatestBracketWinner = async () => {
    try {
      const response = await axios.get(API_URLS.Get_Latest_BracketWinners);
      setBracketWinner(response.data.data.result);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getPlayerById();
    getAllTeams();
    fetchLatestBracketWinner();
  }, []);
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="Rules Modal"
      className="modal-content player orange-scrollbar"
      overlayClassName="modal-overlay"
      style={{ ...customStyles }}
    >
      <div
        className="registration_modal playerRegistration_modal "
        id="playerRegistration"
      >
        <div className="modal_body">
          <div className="slider_wrap">
            <div className="slick teamReg_slick">
              <Slider {...settings}>
                {bracketWinner.map((bracket, index) => (
                  <div key={index} className="slick_item">
                    <img
                      src={`${BASE_URL}/api/v1/files/BracketPhoto/${bracket.Photo}`}
                      alt={bracket.Detail}
                    />
                    <div className="content">
                      <h3>{bracket.Detail}</h3>
                      <span>{formatDate(bracket.ProAmPlayingDate)}</span>
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
          </div>

          <div
            className="form_wrap teamReg_form orange-scrollbar"
            style={{
              overflowY: "auto",
              maxHeight: "600.2px",
              overflowX: "hidden",
            }}
          >
            <div className="close-button">
              <h3>Player Profile Update</h3>
              <svg
                width="30"
                height="30"
                viewBox="0 0 24 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg "
                onClick={onClose}
              >
                <path
                  d="M12 23.25C6.07 23.25 1.25 18.43 1.25 12.5C1.25 6.57 6.07 1.75 12 1.75C17.93 1.75 22.75 6.57 22.75 12.5C22.75 18.43 17.93 23.25 12 23.25ZM12 3.25C6.9 3.25 2.75 7.4 2.75 12.5C2.75 17.6 6.9 21.75 12 21.75C17.1 21.75 21.25 17.6 21.25 12.5C21.25 7.4 17.1 3.25 12 3.25Z"
                  fill="#1B1B1B"
                />
                <path
                  d="M9.17035 16.08C8.98035 16.08 8.79035 16.01 8.64035 15.86C8.35035 15.57 8.35035 15.09 8.64035 14.8L14.3004 9.13999C14.5904 8.84999 15.0704 8.84999 15.3604 9.13999C15.6504 9.42999 15.6504 9.90998 15.3604 10.2L9.70035 15.86C9.56035 16.01 9.36035 16.08 9.17035 16.08Z"
                  fill="#1B1B1B"
                />
                <path
                  d="M14.8304 16.08C14.6404 16.08 14.4504 16.01 14.3004 15.86L8.64035 10.2C8.35035 9.90998 8.35035 9.42999 8.64035 9.13999C8.93035 8.84999 9.41035 8.84999 9.70035 9.13999L15.3604 14.8C15.6504 15.09 15.6504 15.57 15.3604 15.86C15.2104 16.01 15.0204 16.08 14.8304 16.08Z"
                  fill="#1B1B1B"
                />
              </svg>
            </div>

            <form
              onSubmit={handleSubmit}
              className="row"
              style={{ marginRight: "10px" }}
            >
              <div className="col-md-6 input_group">
                <label htmlFor="FirstName">
                  First Name <span className="edd-required-indicator">*</span>
                </label>

                <input
                  name="FirstName"
                  value={formData.FirstName}
                  onChange={handleChange}
                  id="FirstName"
                  className="input"
                  placeholder="First Name"
                  type="text"
                />
              </div>
              <div className="col-md-6 input_group">
                <label htmlFor="LastName">
                  Last Name <span className="edd-required-indicator">*</span>
                </label>
                <input
                  name="LastName"
                  id="LastName"
                  value={formData.LastName}
                  onChange={handleChange}
                  className="input"
                  placeholder="Last Name"
                  type="text"
                />
              </div>
              <div className="col-md-6 input_group">
                <label htmlFor="EmailId">
                  Email ID <span className="edd-required-indicator">*</span>
                </label>
                <input
                  name="EmailId"
                  id="EmailId"
                  value={formData.EmailId}
                  onChange={handleChange}
                  className="input"
                  placeholder="Email ID"
                  type="text"
                />
              </div>

              <div className="col-md-6 input_group">
                <label htmlFor="Password">
                  Password <span className="edd-required-indicator">*</span>
                </label>
                <input
                  name="Password"
                  id="Password"
                  value={formData.Password}
                  onChange={handleChange}
                  className="input"
                  placeholder="Password"
                  type="text"
                />
              </div>
              <div className="col-md-6 input_group">
                <label htmlFor="PlayerNumber">
                  Jersy # <span className="edd-required-indicator">*</span>
                </label>
                <input
                  name="PlayerNumber"
                  id="PlayerNumber"
                  value={formData.PlayerNumber}
                  onChange={handleChange}
                  className="input"
                  placeholder="Jersy #"
                  type="text"
                />
              </div>
              <div
                className="col-md-6 input_group"
                style={{ display: "flex", alignItems: "center" }}
              >
                <label
                  htmlFor="isAvailable"
                  style={{ marginRight: "10px", marginTop: "5px" }}
                >
                  Are you available?
                </label>
                <input
                  type="checkbox"
                  id="isAvailable"
                  name="isAvailable"
                  checked={formData.isAvailable}
                  onChange={handleChange}
                />
              </div>
              <div className="col-md-12 input_group">
                <label htmlFor="edd_user_login">
                  Select Team
                  <span className="edd-required-indicator">*</span>
                </label>

                <div>
                  <Select
                    id="edd_user_login"
                    className=""
                    styles={{ width: "100%" }}
                    options={options}
                    isMulti
                    onChange={handleselectChanges}
                    isDisabled={teamListData.length === 0}
                    value={defaultSelectedOptions}
                  />
                </div>
              </div>

              <div className="col-md-12 input_group">
                <label htmlFor="State">Location</label>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <select
                    id="State"
                    name="State"
                    className="input input_select"
                    value={formData.State}
                    onChange={handleChange}
                    style={{ marginTop: "13px" }}
                  >
                    <option value="">State</option>
                    <option value="AL">Alabama</option>
                    <option value="AK">Alaska</option>
                    <option value="AZ">Arizona</option>
                    <option value="AR">Arkansas</option>
                    <option value="CA">California</option>
                    <option value="CO">Colorado</option>
                    <option value="CT">Connecticut</option>
                    <option value="DE">Delaware</option>
                    <option value="DC">District Of Columbia</option>
                    <option value="FL">Florida</option>
                    <option value="GA">Georgia</option>
                    <option value="HI">Hawaii</option>
                    <option value="ID">Idaho</option>
                    <option value="IL">Illinois</option>
                    <option value="IN">Indiana</option>
                    <option value="IA">Iowa</option>
                    <option value="KS">Kansas</option>
                    <option value="KY">Kentucky</option>
                    <option value="LA">Louisiana</option>
                    <option value="ME">Maine</option>
                    <option value="MD">Maryland</option>
                    <option value="MA">Massachusetts</option>
                    <option value="MI">Michigan</option>
                    <option value="MN">Minnesota</option>
                    <option value="MS">Mississippi</option>
                    <option value="MO">Missouri</option>
                    <option value="MT">Montana</option>
                    <option value="NE">Nebraska</option>
                    <option value="NV">Nevada</option>
                    <option value="NH">New Hampshire</option>
                    <option value="NJ">New Jersey</option>
                    <option value="NM">New Mexico</option>
                    <option value="NY">New York</option>
                    <option value="NC">North Carolina</option>
                    <option value="ND">North Dakota</option>
                    <option value="OH">Ohio</option>
                    <option value="OK">Oklahoma</option>
                    <option value="OR">Oregon</option>
                    <option value="PA">Pennsylvania</option>
                    <option value="RI">Rhode Island</option>
                    <option value="SC">South Carolina</option>
                    <option value="SD">South Dakota</option>
                    <option value="TN">Tennessee</option>
                    <option value="TX">Texas</option>
                    <option value="UT">Utah</option>
                    <option value="VT">Vermont</option>
                    <option value="VA">Virginia</option>
                    <option value="WA">Washington</option>
                    <option value="WV">West Virginia</option>
                    <option value="WI">Wisconsin</option>
                    <option value="WY">Wyoming</option>
                  </select>
                  <input
                    style={{
                      marginBottpm: "-20px",
                      marginLeft: "5px",
                    }}
                    name="City"
                    id="City"
                    value={formData.City}
                    onChange={handleChange}
                    className="input"
                    placeholder="City"
                    type="text"
                  />
                </div>
              </div>

              <div className="col-md-12 input_group">
                <label htmlFor="Address">Address</label>
                <textarea
                  value={formData.Address}
                  onChange={handleChange}
                  name="Address"
                  id="Address"
                  className="input"
                  placeholder="Address"
                  type="text"
                />
              </div>
              <div className="col-md-12 input_group">
                <label htmlFor="edd_user_pass_confirm">
                  WHAT LEVEL OF PLAY IS THE LEAGUE?
                  <span className="edd-required-indicator">*</span>
                </label>
                <select
                  id="PlayLevel"
                  name="PlayLevel"
                  value={formData.PlayLevel || "0"}
                  onChange={handleChange}
                  className="input input_select"
                >
                  <option value="0">Select</option>
                  <option value="1">Level D</option>
                  <option value="2">Level C</option>
                  <option value="3">Level B</option>
                  <option value="4">Level A</option>
                  <option value="5">MAJOR</option>
                </select>
              </div>
              <div
                className="col-md-6 input_group"
                id="edd-user-pass-confirm-wrap"
              >
                <label htmlFor="PhoneNumber">Phone Number</label>
                <input
                  value={formData.PhoneNumber}
                  onChange={handleChange}
                  name="PhoneNumber"
                  id="PhoneNumber"
                  className="input"
                  placeholder="Phone Number"
                  type="text"
                />
              </div>
              <div
                className="col-md-6 input_group"
                id="edd-user-pass-confirm-wrap"
              >
                <label htmlFor="Bats">Bats</label>
                <select
                  id="Bats"
                  value={formData.Bats}
                  onChange={handleChange}
                  className="input input_select"
                >
                  <option value="Right">Right</option>
                  <option value="Left">Left</option>
                  <option value="Both">Both</option>
                </select>
              </div>
              <div
                className="col-md-6 input_group"
                id="edd-user-pass-confirm-wrap"
              >
                <label htmlFor="Throws">Throws</label>
                <select
                  id="Throws"
                  value={formData.Throws}
                  onChange={handleChange}
                  className="input input_select"
                >
                  <option value="Right">Right</option>
                  <option value="Left">Left</option>
                  <option value="Both">Both</option>
                </select>
              </div>
              <div
                className="col-md-6 input_group"
                id="edd-user-pass-confirm-wrap"
              >
                <label htmlFor="Position">Field Position</label>
                <select
                  id="Position"
                  value={formData.Position}
                  onChange={handleChange}
                  className="input input_select"
                >
                  <option value="Out">Out</option>
                  <option value="P">P</option>
                  <option value="C">C</option>
                  <option value="1B">1B</option>
                  <option value="2B">2B</option>
                  <option value="3B">3B</option>
                  <option value="SS">SS</option>
                  <option value="LF">LF</option>
                  <option value="RF">RF</option>
                  <option value="LC">LC</option>
                  <option value="RC">RC</option>
                </select>
              </div>
              <div
                className="col-md-12 input_group"
                id="edd-user-pass-confirm-wrap"
              >
                <label htmlFor="Comments">
                  Comments/Fielding Position/Level Of Play
                </label>
                <input
                  value={formData.Comments}
                  onChange={handleChange}
                  name="Comments"
                  id="Comments"
                  className="input"
                  placeholder="Comments"
                  type="text"
                ></input>
              </div>
              <div className="col-md-12 input_group">
                <label htmlFor="PlayerPhoto">Player Photo</label>
                <button
                  className="btn btn_primary w-20"
                  onClick={handleButtonClickPlayer}
                >
                  Choose File
                </button>
                <input
                  type="file"
                  ref={fileInputRefPlayer}
                  style={{ display: "none" }}
                  onChange={handleImageChange}
                />
                {(formData.PlayerPhoto || imageName) && (
                  <img
                    src={
                      formData?.PlayerPhoto
                        ? `${BASE_URL}/api/v1/files/PlayerPhoto/${formData.PlayerPhoto}`
                        : imageName
                    }
                    alt="Player Image"
                    style={{ maxWidth: "150px" }}
                  />
                )}
              </div>
              <div className="col-md-12 input_group">
                <label htmlFor="License">License Image</label>
                <button
                  className="btn btn_primary w-20"
                  onClick={handleButtonClick}
                >
                  Choose File
                </button>
                <input
                  type="file"
                  ref={fileInputRef}
                  style={{ display: "none" }}
                  onChange={handleLicenseImageChange}
                />
                {(formData.License || imageName) && (
                  <img
                    src={
                      formData?.License
                        ? `${BASE_URL}/api/v1/files/LicenseImage/${formData.License}`
                        : imageName
                    }
                    alt="License Image"
                    style={{ maxWidth: "150px" }}
                  />
                )}
              </div>
              <div className="col-md-12 btn_wrap">
                <button
                  type="submit"
                  className="btn btn_primary w-100"
                  disabled={isRegisterDisabled}
                >
                  {isRegisterDisabled
                    ? "Wait to Upload the image"
                    : "Update Player Profile"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Modal>
  );
};
export { PlayerProf };
