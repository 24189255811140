import swal from "sweetalert";

export const showAlert = (title, text, icon = "info") => {
  return swal({
    title,
    text,
    icon,
    buttons: {
      confirm: {
        text: "OK",
        value: true,
        visible: true,
        className: "custom-swal-button",
        closeModal: true,
      },
    },
  });
};
