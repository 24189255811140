import React, { useEffect, useState } from "react";
import axios from "axios";
import { Alert } from "../../../components/Alert";

import ManageHallOfFameModal from "./components/ManageHallOfFameModal";

import { API_URLS } from "../../../Utils";

export const YearPicker = ({ value, handleChange }) => {
  return (
    <div className="col-md-12 input_group">
      <label htmlFor="year">
        Select Year
        <span className="edd-required-indicator">*</span>
      </label>
      <select
        id="year"
        style={{ width: "100%" }}
        value={value}
        onChange={(e) => handleChange(e.target.value)}
        className="input input_select"
      >
        <option value="2025">2025</option>
        <option value="2024">2024</option>
        <option value="2023">2023</option>
        <option value="2022">2022</option>
        <option value="2021">2021</option>
        <option value="2020">2020</option>
        <option value="2019">2019</option>
        <option value="2018">2018</option>
        <option value="2017">2017</option>
      </select>
    </div>
  );
};

const HallTable = ({ halls, onEdit, onDelete }) => {
  return (
    <div className="">
      <div className="tabs_container">
        <table>
          <thead>
            <tr>
              <th>Hall Of Fame ID</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {halls?.map((hall) => (
              <tr key={hall?.HallOfFameID}>
                <td>{hall?.HallOfFameID}</td>

                <td>
                  <button
                    className="btn btn_primary text_upp"
                    style={{ margin: "1%" }}
                    onClick={() => onEdit(hall)}
                  >
                    Edit
                  </button>

                  <button
                    className="btn btn_primary text_upp"
                    style={{ margin: "1%" }}
                    onClick={() => onDelete(hall)}
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};
const HallOfFame = () => {
  const [selectedYear, setSelectedYear] = useState(2025);
  const [hallOfFames, setHallOfFames] = useState([]);
  const [loading, setLoading] = useState(false);
  const [addNewHall, setAddNewHall] = useState(false);
  const [editHallOfFame, setEditHallOfFame] = useState(null);
  const [deleteHallOfFame, setDeleteHallOfFame] = useState(null);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const getHallByYearHandler = async (Year) => {
    setLoading(true);
    try {
      const {
        data: { data },
      } = await axios.get(API_URLS.GET_HALL_OF_FAME_BY_YEAR, {
        params: {
          Year,
        },
      });

      setHallOfFames(data.result);
      setLoading(false);
    } catch (error) {
      alert(error.response.data.message);
      setLoading(false);
    }
  };
  const deleteHallOfFameHandler = async (HallOfFameId) => {
    try {
      setDeleteLoading(true);
      await axios.delete(API_URLS.DELETE_HALL_OF_FAME, {
        params: {
          HallOfFameId,
        },
      });
      setDeleteHallOfFame(null);
      getHallByYearHandler(selectedYear);
      setDeleteLoading(false);
    } catch (error) {
      // console.error("Error:", error);
      setDeleteLoading(false);

      alert("An error occurred while processing the request.");
    }
  };
  useEffect(() => {
    getHallByYearHandler(selectedYear);
  }, [selectedYear]);

  if (loading) {
    return (
      <div className="App">
        <div className="site-content">
          <div
            style={{
              display: "flex",
              flex: 1,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div className="spinner" />
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className="container">
      <div className="site-content">
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <h2 style={{ color: "black", fontWeight: "bold" }}>Hall Of Fame</h2>
          <button
            className="btn btn_primary text_upp"
            onClick={() => setAddNewHall(true)}
          >
            Add Hall Of Fame
          </button>
        </div>
        <YearPicker value={selectedYear} handleChange={setSelectedYear} />

        <HallTable
          halls={hallOfFames}
          onEdit={setEditHallOfFame}
          onDelete={setDeleteHallOfFame}
        />
        <ManageHallOfFameModal
          isOpen={addNewHall || !!editHallOfFame?.HallOfFameID}
          onClose={() => {
            setEditHallOfFame(null);
            setAddNewHall(false);
          }}
          getHallByYearHandler={getHallByYearHandler}
          hallOfFameId={editHallOfFame?.HallOfFameID}
          year={editHallOfFame?.Year}
          description={editHallOfFame?.Description}
          image={editHallOfFame?.Name}
        />
        {deleteHallOfFame?.HallOfFameID && (
          <Alert
            message="Are you sure want to delete this hall of fame?"
            confirmPress={() =>
              deleteHallOfFameHandler(deleteHallOfFame?.HallOfFameID)
            }
            disable={deleteLoading}
            cancelPress={() => setDeleteHallOfFame(null)}
          />
        )}
      </div>
    </div>
  );
};

export { HallOfFame };
