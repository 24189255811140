//export const BASE_URL = process.env.REACT_APP_BASE_URL;

export const BASE_URL = "https://api.proamsoftball.com:8082"; // Prooduction url
//export const BASE_URL = "https://api.proamsoftball.com:8089"; // dev url

//export const BASE_URL = "http://localhost:8082"; // Local dev url

export const API_URLS = {
  GET_Session: `${BASE_URL}/api/session`,

  ADD_PLAYER_SEND_EMAIL: `${BASE_URL}/api/v1/web/email/player-added`,
  ADD_TEAM_SEND_EMAIL: `${BASE_URL}/api/v1/web/email/team-added`,

  LOGIN_API: `${BASE_URL}/api/v1/web/auth/login`,

  GET_ALL_TEAMS: `${BASE_URL}/api/v1/web/team/get_all_teams`,
  GET_TEAM_BY_ID: `${BASE_URL}/api/v1/web/team/get_Team_By_Id`,
  ADD_TEAM: `${BASE_URL}/api/v1/web/team/AddTeam`,
  ADD_EXISTING_TEAM_IN_TOURNAMENT: `${BASE_URL}/api/v1/web/team/add_existing_team_in_Tournament`,
  UPDATE_TEAM: `${BASE_URL}/api/v1/web/team/UpdateTeam`,
  UPDATE_TEAM_LINE_UP: `${BASE_URL}/api/v1/web/team/update_Team_LineUp`,
  DELETE_TEAM: `${BASE_URL}/api/v1/web/team/DeleteTeam`,
  ISPAID_TEAM: `${BASE_URL}/api/v1/web/team/isPaidTeam`,
  ADD_EXISTING_TEAM_IN_TOURNAMENT: `${BASE_URL}/api/v1/web/team/add_existing_team_in_Tournament`,
  UPDATE_Annual_Subscription: `${BASE_URL}/api/v1/web/park/payment_Success_Annual_Fee`,

  GET_HALL_OF_FAME_BY_YEAR: `${BASE_URL}/api/v1/web/hall/get_hall_by_year`,
  ADD_UPDATE_HALL_OF_FAME: `${BASE_URL}/api/v1/web/hall/add_update_hall`,
  UPLOAD_HALL_OF_FAME_IMAGE: `${BASE_URL}/api/v1/web/hall/upload_image?upload_folder=HOFPhoto`,
  DELETE_HALL_OF_FAME: `${BASE_URL}/api/v1/web/hall/delete_hall_of_fame`,

  GET_PLAYERS_BY_TEAM_ID: `${BASE_URL}/api/v1/web/player/get_players_by_teamid`,
  GET_PLAYERS_BY_PLAYER_ID: `${BASE_URL}/api/v1/web/player/get_players_by_playerid`,
  GET_All_Agents: `${BASE_URL}/api/v1/web/player/Get_All_Agents`,
  GET_ALL_PLAYER: `${BASE_URL}/api/v1/web/player/get_all_players`,
  GET_ALL_Available_PLAYER: `${BASE_URL}/api/v1/web/player/get_all_available_players`,
  ADD_Player: `${BASE_URL}/api/v1/web/player/add_player`,
  UPDATE_Player: `${BASE_URL}/api/v1/web/player/Update_Player`,
  DELETE_PLAYERS_BY_ID_TEAM_ID: `${BASE_URL}/api/v1/web/player/DeletePlayer`,
  DELET_PLAYER_PERMENANTELY: `${BASE_URL}/api/v1/web/player/DeletePlayerPermanently`,
  UPLOAD_PLAYER_IMAGE: `${BASE_URL}/api/v1/web/player/upload_player_image?upload_folder=PlayerPhoto`,
  UPLOAD_TEAM_IMAGE: `${BASE_URL}/api/v1/web/team/upload_team_image?upload_folder=TeamPhoto`,
  UPLOAD_LICENSE_IMAGE: `${BASE_URL}/api/v1/web/player/upload_license_image?upload_folder=LicenseImage`,
  ADD_EXISTING_PLAYER: `${BASE_URL}/api/v1/web/player/add_existing_player`,
  SET_ISANONYMOUS_TO_TRUE: `${BASE_URL}/api/v1/web/player/Set_Is_Anonymous_To_True`,

  GET_TEAMS_BY_ID_WITH_TYPE: `${BASE_URL}/api/v1/web/park/get_teams_by_park_id_with_type`,
  GET_TEAMS_BY_PARK_ID_AND_TEAM_ID: `${BASE_URL}/api/v1/web/park/get_teams_by_park_id_and_team_id`,
  Get_Latest_BracketWinners: `${BASE_URL}/api/v1/web/park/get_latest_bracket_winners`,
  GET_PROAM_BY_ParkId: `${BASE_URL}/api/v1/web/park/get_proam_tournaments_by_parkid`,
  GET_ALL_SCOREKEEPER: `${BASE_URL}/api/v1/web/park/get_all_score_keeper`,
  GET_SCOREKEEPER_BY_ID: `${BASE_URL}/api/v1/web/park/get_score_keeper_by_id`,
  GET_ALL_50_TOURNAMENTS: `${BASE_URL}/api/v1/web/park/get_all_proam_tournaments`,
  GET_ALL_UMPIRES: `${BASE_URL}/api/v1/web/park/get_all_Umpire`,
  GET_UMPIRE_By_ID: `${BASE_URL}/api/v1/web/park/get_Umpire_by_id`,
  GET_BRACKET_WINNER_BY_PARKID: `${BASE_URL}/api/v1/web/park/get_bracket_winners_by_parkid`,
  GET_BRACKET_WINNER_BY_ID: `${BASE_URL}/api/v1/web/park/get_bracket_winners_by_id`,
  GET_PARK_BY_PARKID: `${BASE_URL}/api/v1/web/park/get_park_by_ParkId`,
  ADD_BRACKET_WINNER: `${BASE_URL}/api/v1/web/park/add_bracket_winner`,
  ADD_SCHEDULE: `${BASE_URL}/api/v1/web/park/add_schedule`, //This One
  ADD_SCOREKEEPER: `${BASE_URL}/api/v1/web/park/add_score_keeper`,
  ADD_PARK: `${BASE_URL}/api/v1/web/park/add_Park`,
  ADD_PROAM_TOURNAMENT: `${BASE_URL}/api/v1/web/park/add_proam_tournament`,
  UPLOAD_PROAM_TOURNAMENT_IMAGE: `${BASE_URL}/api/v1/web/park/upload_proam_tournament_image?upload_folder=TournamentPhoto`,
  UPDATE_BRACKET_WINNER: `${BASE_URL}/api/v1/web/park/update_bracket_winner`,
  UPLOAD_BRACKET_IMAGE: `${BASE_URL}/api/v1/web/park/upload_bracket_image?upload_folder=BracketPhoto`,
  UP_COMING_PROAM_TOURNAMENTS: `${BASE_URL}/api/v1/web/park/get_upcoming_proam_tournaments`,
  UP_COMING_PROAM_TOURNAMENTS_FOR_COACH: `${BASE_URL}/api/v1/web/park/get_upcoming_proam_tournaments_for_coach`,
  UPATE_SCHEDULE: `${BASE_URL}/api/v1/web/park/updateschedule`,
  UPDATE_SCOREKEEPER: `${BASE_URL}/api/v1/web/park/update_score_keeper`,
  UPDATE_PARK: `${BASE_URL}/api/v1/web/park/update_Park`,
  UPDATE_PAYMENT_STATUS: `${BASE_URL}/api/v1/web/park/update_payment_status`,
  UPDATE_PROAM_TOURNAMENT: `${BASE_URL}/api/v1/web/park/update_proam_tournament`,
  UPDATE_SCHEDULE: `${BASE_URL}/api/v1/web/park/update_Schedule`, //This One
  DELETE_SCOREKEEPER_BY_ID: `${BASE_URL}/api/v1/web/park/delete_score_keeper_by_id`,
  DELETE_SCHEDULE_BY_ID: `${BASE_URL}/api/v1/web/park/delete_schedule_by_Id`,
  DELETE_BRACKET_WINNER_BY_ID: `${BASE_URL}/api/v1/web/park/delete_bracket_winner`,
  ADD_UMPIRE: `${BASE_URL}/api/v1/web/park/add_Umpire`,
  UPDATE_UMPIRE: `${BASE_URL}/api/v1/web/park/update_Umpire`,
  ADD_PDF_SCHEDULE: `${BASE_URL}/api/v1/web/park/add_PDF_Schedule`,
  Upload_PDF_SCHEDULE: `${BASE_URL}/api/v1/web/park/upload_PDF_Schedule?upload_folder=PDFSchedule`,
  Upload_FIFTY_STANDING: `${BASE_URL}/api/v1/web/park/upload_50_Standing?upload_folder=FiftyStanding`,
  Upload_SIXTY_STANDING: `${BASE_URL}/api/v1/web/park/upload_60_Standing?upload_folder=SixtyStanding`,
  GET_FIFTY_STANDING: `${BASE_URL}/api/v1/web/park/get_50_Standing`,
  GET_SIXTY_STANDING: `${BASE_URL}/api/v1/web/park/get_60_Standing`,
  GET_PDF_SCHEDULE: `${BASE_URL}/api/v1/web/park/get_PDF_Schedule`,
  GET_ALL_PDF_SCHEDULE: `${BASE_URL}/api/v1/web/park/getAll_PDF_Schedule`,
  CHECK_COACH_EXIST: `${BASE_URL}/api/v1/web/park/check_Existing_Coach`,
  CHECK_PLAYER_EXIST: `${BASE_URL}/api/v1/web/park/check_Existing_Player`,

  GET_ALL_LEGENDS: `${BASE_URL}/api/v1/web/legend/all-legends`,
  GET_LEGEND_BY_ID: `${BASE_URL}/api/v1/web/legend/get-legend-by-id`,
  ADD_LEGEND: `${BASE_URL}/api/v1/web/legend/add-legend`,
  UPDATE_LEGEND: `${BASE_URL}/api/v1/web/legend/update-legend`,
  DELETE_LEGEND: `${BASE_URL}/api/v1/web/legend/delete_legend`,
  UPLOAD_LEGEND_IMAGE: `${BASE_URL}/api/v1/web/legend/upload_legend_image?upload_folder=LegendImage`,
};

// export const Globals = {
//   urlVarSeperator: "&",
//   postUrl: "https://www.paypal.com/cgi-bin/webscr?",
//   postUrlTEST: "https://www.sandbox.paypal.com/cgi-bin/webscr?",
//   cmdSubscription: "cmd=_xclick-subscriptions",
//   cmdSinglePayment: "cmd=_xclick",
//   business: "business=playballstats@gmail.com",
//   recurring: "src=1",
//   reattempt: "sra=1",
//   returnUrl: "return=http://www.proamsoftball.com/Pages/Thankyou.aspx",
//   cancelReturnUrl:
//     "cancel_return=http://www.proamsoftball.com/Pages/CoachRegistration.aspx?coachId",
// };
export const Globals = {
  postUrl: "https://www.paypal.com/cgi-bin/webscr",
  cmdSinglePayment: "?cmd=_xclick",
  business: "playballstats@gmail.com",
  urlVarSeperator: "&",
  returnUrl: "https://www.proamsoftball.com/admin/payment-success",
  cancelReturnUrl: "cancel_return=https://www.proamsoftball.com/admin",
};
export const GlobalsForAnnualFee = {
  postUrl: "https://www.paypal.com/cgi-bin/webscr",
  cmdSinglePayment: "?cmd=_xclick",
  business: "playballstats@gmail.com",
  urlVarSeperator: "&",
  returnUrl:
    "https://www.proamsoftball.com/admin/payment-success-Annual-Subscription",
  cancelReturnUrl: "cancel_return=https://www.proamsoftball.com/admin",
};
