import React, { useState, useEffect } from "react";
import axios from "axios";
import { BASE_URL } from "../../../Utils";
import swal from "sweetalert";

const Stats = () => {
  const [selectedYear, setSelectedYear] = useState("2025");
  const [selectedTournament, setSelectedTournament] = useState("");
  const [selectedTournamentName, setSelectedTournamentName] = useState(null);
  const [activeTab, setActiveTab] = useState("Schedules");
  const [activeSubTab, setActiveSubTab] = useState("Win/Loss");
  const [tournamentList, setTournamentList] = useState([]);
  const [schedules, setSchedules] = useState([]);

  const fnTournamentList = () => {
    //debugger;
    const url = `${BASE_URL}/api/v1/web/park/get_all_proam_tournaments_by_year?year=${selectedYear}`;
    fetch(url)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Network response was not ok.");
        }
      })
      .then((data) => {
        setTournamentList(data.data.result);
      })
      .catch((error) => {
        // console.error(error.message);
      });
  };

  const getSchedule = async () => {
    //debugger;
    try {
      const url = `${BASE_URL}/api/v1/web/park/get_all_park_schedules?ParkId=${selectedTournament}&SeasonOrTour=${selectedTournamentName}&Year=${selectedYear}`;

      try {
        const response = await axios(url);

        if (response.status === 200) {
          const data = await response.data.data.result;

          if (data != null && Array.isArray(data)) {
            setSchedules(
              data.map((schedule) => ({
                ...schedule,
                Date: new Date(schedule.Date).toLocaleDateString(),
              }))
            );
          } else {
            swal("No schedule found for the selected tournament.");
          }
        } else {
          throw new Error(
            "Failed to fetch schedule data. Status code: " + response.status
          );
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    } catch (error) {
      alert(error.message);
    }
  };

  useEffect(() => {
    fnTournamentList();
  }, []);

  useEffect(() => {
    if (selectedTournament !== "") {
      getSchedule();
    }
  }, [selectedTournament]);

  return (
    <section
      className="match_section"
      id="schedule"
      style={{ backgroundImage: "none" }}
    >
      <div className="container">
        <div className="tabs_wrapper" data-aos="fade" data-aos-delay="50">
          <div className="table_wrap">
            <div className="tab_wrap">
              <select
                value={selectedYear}
                onChange={(e) => setSelectedYear(e.target.value)}
                className="selection"
              >
                {/* <select value="2024" className="selection"> */}
                <option value="2018">2018</option>
                <option value="2019">2019</option>
                <option value="2020">2020</option>
                <option value="2021">2021</option>
                <option value="2022">2022</option>
                <option value="2023">2023</option>
                <option value="2024">2024</option>
                <option value="2025">2025</option>
              </select>

              <select
                value={selectedTournament}
                onChange={(e) => {
                  setSelectedTournament(e.target.value);
                  const selectedTournamentItem = tournamentList.find(
                    (tournament) =>
                      tournament.ParkId === parseInt(e.target.value, 10)
                  );
                  setSelectedTournamentName(
                    selectedTournamentItem
                      ? selectedTournamentItem.FirstName
                      : null
                  );
                }}
                className="selection"
              >
                <option>Select Tournament</option>
                {tournamentList.map((tournament) => (
                  <option key={tournament.ParkId} value={tournament.ParkId}>
                    {tournament.FirstName}
                  </option>
                ))}
              </select>
              <span
                id="Schedules"
                className={`tab ${activeTab === "Schedules" ? "active" : ""}`}
                onClick={() => setActiveTab("Schedules")}
              >
                Schedules
              </span>
              {/* <select name="" id="" className="selection">
          <option value="">Select Tournament</option>
          <option value="">Tournament 02</option>
          <option value="">Tournament 03</option>
        </select> */}
            </div>
            {/* <div className="tab_wrap">
              <span
                id="Stats"
                className={`tab ${activeTab === "Stats" ? "active" : ""}`}
                onClick={() => setActiveTab("Stats")}
              >
                Stats
              </span>
            </div> */}
          </div>
          <div className="tabs_container">
            {/* Schedule */}
            {activeTab === "Schedules" && (
              <div className="tab_content active" data-tab="Schedules">
                <h2
                  style={{
                    color: "black",
                    fontWeight: "bold",
                    textAlign: "center",
                  }}
                >
                  Game Schedule
                </h2>
                <div className="schedule-container orange-scrollbar">
                  {schedules && schedules.length > 0 ? (
                    <div style={{ overflowY: "auto", maxHeight: "500px" }}>
                      <table
                        style={{ width: "100%", borderCollapse: "collapse" }}
                      >
                        <thead
                          style={{
                            position: "sticky",
                            top: 0,
                            background: "#fff",
                          }}
                        >
                          <tr>
                            {/* <th>Game Number</th> */}
                            <th>Day Of Week</th>
                            {/* <th>Division</th> */}
                            <th>Time</th>
                            <th className="team">Visitor Team</th>
                            <th className="team">Home Team</th>
                            <th>Playing Field</th>
                          </tr>
                        </thead>
                        <tbody>
                          {schedules.map((schedule, index) => (
                            <tr key={index} className="schedule-item">
                              {/* <td>{schedule.GameNumber}</td> */}
                              <td>{schedule.DayOfWeek}</td>
                              {/* <td>{schedule.Division}</td> */}
                              <td>{schedule.Time}</td>
                              <td>
                                <div style={{ display: "flex", gap: "0.5rem" }}>
                                  <img
                                    src="/images/team-2.png"
                                    alt="Visitor Team"
                                    style={{
                                      display: "block",
                                      width: "34px",
                                      height: "34px",
                                      objectFit: "contain",
                                    }}
                                  />
                                  <strong
                                    style={{
                                      fontSize: "0.875rem",
                                      fontWeight: 500,
                                      color: "#111827",
                                    }}
                                  >
                                    {schedule.VisitorTeam}
                                  </strong>
                                </div>
                              </td>
                              <td>
                                <div style={{ display: "flex", gap: "0.5rem" }}>
                                  <img
                                    src="/images/team-2.png"
                                    alt="Home Team"
                                    style={{
                                      display: "block",
                                      width: "34px",
                                      height: "34px",
                                      objectFit: "contain",
                                    }}
                                  />
                                  <strong
                                    style={{
                                      fontSize: "0.875rem",
                                      fontWeight: 500,
                                      color: "#111827",
                                    }}
                                  >
                                    {schedule.HomeTeam}
                                  </strong>
                                </div>
                              </td>
                              <td
                                style={{
                                  minWidth: "177px",
                                  padding: "0.8rem 1rem",
                                }}
                              >
                                {schedule.FieldNumber}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  ) : (
                    <h3
                      style={{
                        color: "black",
                        fontWeight: "bold",
                        textAlign: "center",
                        padding: "20px",
                      }}
                    >
                      Please Select The Played Tournament From Drop-Down
                    </h3>
                  )}
                </div>
              </div>
            )}
            {/* Stats */}
            {activeTab === "Stats" && (
              <div className="tab_content active" data-tab="Stats">
                <div class="table_wrap" style={{ "margin-left": "25%" }}>
                  <div class="tab_wrap">
                    <span
                      id="TeamScore"
                      className={`tab ${
                        activeSubTab === "TeamScore" ? "active" : ""
                      }`}
                      onClick={() => setActiveSubTab("TeamScore")}
                    >
                      Team Score
                    </span>
                    <span
                      id="WinLoss"
                      className={`tab ${
                        activeSubTab === "Win/Loss" ? "active" : ""
                      }`}
                      onClick={() => setActiveSubTab("Win/Loss")}
                    >
                      Win/Loss
                    </span>
                    <span
                      id="Schedule"
                      className={`tab ${
                        activeSubTab === "Schedule" ? "active" : ""
                      }`}
                      onClick={() => setActiveSubTab("Schedule")}
                    >
                      Schedule
                    </span>
                    <span
                      id="PointsTable"
                      className={`tab ${
                        activeSubTab === "PointsTable" ? "active" : ""
                      }`}
                      onClick={() => setActiveSubTab("PointsTable")}
                    >
                      Points table
                    </span>
                    <span
                      id="BWinners"
                      className={`tab ${
                        activeSubTab === "BWinners" ? "active" : ""
                      }`}
                      onClick={() => setActiveSubTab("BWinners")}
                    >
                      Bracket Winners
                    </span>
                  </div>
                </div>
                <div className="sub_tab_content">
                  {activeSubTab === "TeamScore" && (
                    <div>Team Score Content</div>
                  )}
                  {activeSubTab === "Win/Loss" && <div>Win/Loss Content</div>}
                  {activeSubTab === "Schedule" && <div>Schedule Content</div>}
                  {activeSubTab === "PointsTable" && (
                    <div>Points Table Content</div>
                  )}
                  {activeSubTab === "BWinners" && (
                    <div>Bracket Winners Content</div>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Stats;
