import React from "react";
import Modal from "react-modal";
import { GlobalsForAnnualFee } from "../../../../../Utils";
import { v4 as uuidv4 } from "uuid";

const AnnualFees = ({ isOpen, onClose, ParkId }) => {
  const customStyles = {
    content: {
      width: "80%",
      maxWidth: "1015.2px",
      padding: "20px",
      overflowY: "auto",
      maxHeight: "96%",
      margin: "auto",
    },
  };

  const buildPaymentLink = () => {
    const baseAmount = 50.0;
    const processingFeeRate = 3.49 / 100;
    const processingFee = baseAmount * processingFeeRate + 0.49; // Adding Fixed Fee
    const totalAmount = (baseAmount + processingFee).toFixed(2);

    const amount = `amount=${totalAmount}`;
    const product = `item_name=${
      new URLSearchParams(window.location.search).get("TourName") || "Unknown"
    } (Base Fee: $${baseAmount}, Processing Fee: $${processingFee.toFixed(2)})`;
    const quantity = "quantity=1";

    // Generate a unique UUID
    const uniqueId = uuidv4();

    let paymentLink = `${GlobalsForAnnualFee.postUrl}${GlobalsForAnnualFee.cmdSinglePayment}${GlobalsForAnnualFee.urlVarSeperator}business=${GlobalsForAnnualFee.business}${GlobalsForAnnualFee.urlVarSeperator}${product}${GlobalsForAnnualFee.urlVarSeperator}${amount}${GlobalsForAnnualFee.urlVarSeperator}${quantity}${GlobalsForAnnualFee.urlVarSeperator}`;

    // Use the UUID in the return URL
    paymentLink += `return=${encodeURIComponent(
      `${GlobalsForAnnualFee.returnUrl}?uuid=${uniqueId}`
    )}${GlobalsForAnnualFee.urlVarSeperator}`;

    paymentLink += `cancel_return=${GlobalsForAnnualFee.cancelReturnUrl}`;

    return paymentLink;
  };

  const handlePaymentClick = (ParkId) => {
    if (!ParkId) {
      console.error("ParkId is required for the payment link.");
      return;
    }
    const paymentLink = buildPaymentLink(ParkId);
    window.location.href = paymentLink;
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="Rules Modal"
      className="modal-content orange-scrollbar"
      overlayClassName="modal-overlay"
      style={{ ...customStyles }}
    >
      <button
        onClick={onClose}
        style={{
          position: "absolute",
          top: "10px",
          right: "10px",
          background: "transparent",
          border: "none",
          fontSize: "1.5rem",
          cursor: "pointer",
          color: "#6c757d",
        }}
        aria-label="Close Modal"
      >
        &times;
      </button>
      <h1
        style={{
          color: "#343a40",
          fontSize: "1.5rem",
          fontWeight: "bold",
          textAlign: "center",
          marginBottom: "1rem",
        }}
      >
        Have You Paid Your Annual Fees ??
      </h1>
      <p
        style={{
          color: "#6c757d",
          fontSize: "1.1rem",
          textAlign: "center",
          marginBottom: "2rem",
        }}
      >
        Please pay Your Annual Subscription Fee $50 for the 2025 season
      </p>
      <div className="col-md-12 btn_wrap">
        <button className="btn btn_primary w-100" onClick={handlePaymentClick}>
          Pay Now Through PayPal !!
        </button>
      </div>
    </Modal>
  );
};
export default AnnualFees;
