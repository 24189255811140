import React from "react";

import "./index.css";

import { Routes, Route, Navigate, useNavigate } from "react-router-dom";
import { Client } from "./pages/client/client.page";
import Admin from "./pages/admin/admin.page";

import { AllPlayers } from "./pages/admin/AllPlayers/AllPlayers.page";
import { Schedule } from "./pages/admin/Schedule/Schedule.page";
import ManageScorekeepers from "./pages/admin/ManageScoreKeeper/ManageScorekeeper";
import { AdminNavbar } from "./pages/admin/Components/AdminNavbar/AdminNavbar";
import { Manage50Tournaments } from "./pages/admin/Manage50Tournaments/Manage50Tournaments";
import { Manage50Teams } from "./pages/admin/Manage50Teams/Manage50Teams";
import { Manage50TeamPlayers } from "./pages/admin/Manage50TeamPlayers/Manage50TeamPlayers";
import { Umpire } from "./pages/admin/ManageUmpire/ManageUmpire";
import ManageBrackets from "./pages/admin/ManageBrackets/ManageBrackets";
import { HallOfFame } from "./pages/admin/HallOfFame/HallOfFame";
import ParkReg from "./pages/admin/ParkRegistration/ParkRegistration";
import constants from "./utils/constants";
import upcomingTournaments from "./pages/admin/upcomingTournaments/upcomingTournaments";
import ProAmRules from "./pages/admin/ProAmRules/ProAmRules";
import ManageHotel from "./pages/admin/ManageHotel/ManageHotel";
import Stats from "./pages/admin/schedule&stats/Stats";
import AvailablePlayer from "./pages/admin/AvailablePlayer/AvailablePlayer";
import { AdminFooter } from "./pages/admin/Components/Footer/AdminFooter";
import RememberingLegends from "./pages/admin/RememberingLegends/RememberingLegends";
import Announcements from "./pages/admin/Announcements/Announcements";
import { Paymentsuccess } from "./pages/admin/ProAmRules/Paymentsuccess";
import AllPlayerForCoach from "./pages/admin/AllPlayersForCoach/AllPlayerForCoach";
import PaymentSuccessAnnualFee from "./pages/admin/Components/AdminNavbar/Components/PaymentSuccessAnnualFee";

function App() {
  const navigate = useNavigate();

  const isAuthenticated = () => {
    const SUPERADMIN = window.sessionStorage.getItem("SuperAdminId");
    const TeamId = window.sessionStorage.getItem("TeamId");
    const PlayerId = window.sessionStorage.getItem("PlayerId");
    if (SUPERADMIN || TeamId || PlayerId) {
      return true;
    } else {
      return false;
    }
  };

  const hasPermission = (allowedRoles) => {
    const userRole = window.sessionStorage.getItem("UserType");
    return allowedRoles.includes(userRole);
  };
  const ProtectedRoute = ({ element: Component, allowedRoles, ...rest }) => {
    console.log("isAuthenticated()", isAuthenticated());
    if (!isAuthenticated()) {
      return <Navigate to="/" />;
    } else if (allowedRoles && !hasPermission(allowedRoles)) {
      return <Navigate to="/admin" />;
    } else {
      return <Component {...rest} />;
    }
  };
  const PublicRoute = ({ element: Component, ...rest }) => {
    return isAuthenticated() === false ? (
      <Component {...rest} />
    ) : (
      <Navigate to="/" />
    );
  };
  return (
    <React.StrictMode>
      {isAuthenticated() && <AdminNavbar />}
      <Routes>
        <Route path="/" element={<PublicRoute element={Client} />} />
        <Route
          path="/admin"
          element={
            <ProtectedRoute
              element={Admin}
              allowedRoles={[
                constants.scopes.SUPERADMIN,
                constants.scopes.COACH,
                constants.scopes.PLAYER,
              ]}
            />
          }
        />
        <Route
          path="/admin/payment-success"
          element={
            <ProtectedRoute
              element={Paymentsuccess}
              allowedRoles={[
                constants.scopes.SUPERADMIN,
                constants.scopes.COACH,
              ]}
            />
          }
        />
        <Route
          path="/admin/payment-success-Annual-Subscription/:ParkId"
          element={
            <ProtectedRoute
              element={PaymentSuccessAnnualFee}
              allowedRoles={[
                constants.scopes.SUPERADMIN,
                constants.scopes.COACH,
              ]}
            />
          }
        />
        <Route
          path="/admin/all-players"
          element={
            <ProtectedRoute
              element={AllPlayers}
              allowedRoles={[constants.scopes.SUPERADMIN]}
            />
          }
        />
        <Route
          path="/admin/Remembering-Legends"
          element={
            <ProtectedRoute
              element={RememberingLegends}
              allowedRoles={[constants.scopes.SUPERADMIN]}
            />
          }
        />
        <Route
          path="/admin/Announcements"
          element={
            <ProtectedRoute
              element={Announcements}
              allowedRoles={[constants.scopes.SUPERADMIN]}
            />
          }
        />
        <Route
          path="/admin/manage-scorekeepers"
          element={
            <ProtectedRoute
              element={ManageScorekeepers}
              allowedRoles={[constants.scopes.SUPERADMIN]}
            />
          }
        />
        <Route
          path="/admin/schedule"
          element={
            <ProtectedRoute
              element={Schedule}
              allowedRoles={[constants.scopes.SUPERADMIN]}
            />
          }
        />
        <Route
          path="/admin/manage-50-tournaments"
          element={
            <ProtectedRoute
              element={Manage50Tournaments}
              allowedRoles={[constants.scopes.SUPERADMIN]}
            />
          }
        />
        <Route
          path="/admin/manage-50-teams"
          element={
            <ProtectedRoute
              element={Manage50Teams}
              allowedRoles={[constants.scopes.SUPERADMIN]}
            />
          }
        />
        <Route
          path="/admin/manage-50_team_players/:TeamId"
          element={
            <ProtectedRoute
              element={Manage50TeamPlayers}
              allowedRoles={[
                constants.scopes.SUPERADMIN,
                constants.scopes.COACH,
              ]}
            />
          }
        />
        <Route
          path="/admin/manage_All_players_coach/:TeamId"
          element={
            <ProtectedRoute
              element={AllPlayerForCoach}
              allowedRoles={[constants.scopes.COACH]}
            />
          }
        />
        <Route
          path="/admin/manage-Hotel/:TeamId"
          element={
            <ProtectedRoute
              element={ManageHotel}
              allowedRoles={[
                constants.scopes.SUPERADMIN,
                constants.scopes.COACH,
              ]}
            />
          }
        />
        <Route
          path="/admin/upcoming_Tournament/:TeamId"
          element={
            <ProtectedRoute
              element={upcomingTournaments}
              allowedRoles={[constants.scopes.COACH, constants.scopes.PLAYER]}
            />
          }
        />
        <Route
          path="/admin/ProAmRules/:TeamName/:ParkId/:TourLeagueName"
          element={
            <ProtectedRoute
              element={ProAmRules}
              allowedRoles={[constants.scopes.COACH]}
            />
          }
        />
        <Route
          path="/admin/manage-umpire"
          element={
            <ProtectedRoute
              element={Umpire}
              allowedRoles={[constants.scopes.SUPERADMIN]}
            />
          }
        />
        <Route
          path="/admin/manage-brackets"
          element={
            <ProtectedRoute
              element={ManageBrackets}
              allowedRoles={[constants.scopes.SUPERADMIN]}
            />
          }
        />
        <Route
          path="/admin/hall_of_fame"
          element={
            <ProtectedRoute
              element={HallOfFame}
              allowedRoles={[constants.scopes.SUPERADMIN]}
            />
          }
        />
        <Route
          path="/admin/Park-Registration"
          element={
            <ProtectedRoute
              element={ParkReg}
              allowedRoles={[constants.scopes.SUPERADMIN]}
            />
          }
        />
        <Route
          path="/admin/stats_&_schedule/:TeamId"
          element={
            <ProtectedRoute
              element={Stats}
              allowedRoles={[constants.scopes.COACH, constants.scopes.PLAYER]}
            />
          }
        />
        <Route
          path="/admin/available_Player/:TeamId"
          element={
            <ProtectedRoute
              element={AvailablePlayer}
              allowedRoles={[constants.scopes.COACH]}
            />
          }
        />

        <Route path="*" element={<>404</>} />
      </Routes>
      {isAuthenticated() && <AdminFooter />}
    </React.StrictMode>
  );
}

export default App;
