import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { API_URLS } from "../../../../../Utils";
import axios from "axios";
import "./AllPlayerPopup.css";

const AllPlayerPopup = ({ isOpen, onClose }) => {
  const [players, setPlayers] = useState([]);
  const customStyles = {
    content: {
      width: "100%", // Adjust this value to your desired width
      maxWidth: "920.2px", // Optional: Set a maximum width
      // Center the modal horizontally
      // padding: "20px",
      overflowY: "auto",
      maxHeight: "99%",

      margin: "auto",

      // Add some padding
      // Add other styles as needed
    },
  };

  const getPlayerHandler = async () => {
    try {
      const {
        data: { data },
      } = await axios.get(API_URLS.GET_ALL_PLAYER, {
        params: {
          TeamId: 0,
        },
      });
      setPlayers(data.result);
      //  console.log(data.result);
    } catch (error) {
      alert(error.response.data.message);
    }
  };

  useEffect(() => {
    if (isOpen) {
      getPlayerHandler();
    }
  }, [isOpen]);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="Players Modal"
      className="modal-content orange-scrollbar"
      overlayClassName="modal-overlay"
      style={{ ...customStyles }}
    >
      <div className="rulesProcedures_modal">
        <div className="modal_body">
          <div className="img_wrap" style={{ height: "300px" }}>
            <img src="images/banner-slider-01.png" alt="" />
            <div className="content">
              <h2>All Players</h2>
            </div>
            <div className="rules-close-button" onClick={onClose}>
              <svg
                width="30"
                height="30"
                viewBox="0 0 24 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12 23.25C6.07 23.25 1.25 18.43 1.25 12.5C1.25 6.57 6.07 1.75 12 1.75C17.93 1.75 22.75 6.57 22.75 12.5C22.75 18.43 17.93 23.25 12 23.25ZM12 3.25C6.9 3.25 2.75 7.4 2.75 12.5C2.75 17.6 6.9 21.75 12 21.75C17.1 21.75 21.25 17.6 21.25 12.5C21.25 7.4 17.1 3.25 12 3.25Z"
                  fill="#1B1B1B"
                />
                <path
                  d="M9.17035 16.08C8.98035 16.08 8.79035 16.01 8.64035 15.86C8.35035 15.57 8.35035 15.09 8.64035 14.8L14.3004 9.13999C14.5904 8.84999 15.0704 8.84999 15.3604 9.13999C15.6504 9.42999 15.6504 9.90998 15.3604 10.2L9.70035 15.86C9.56035 16.01 9.36035 16.08 9.17035 16.08Z"
                  fill="#1B1B1B"
                />
                <path
                  d="M14.8304 16.08C14.6404 16.08 14.4504 16.01 14.3004 15.86L8.64035 10.2C8.35035 9.90998 8.35035 9.42999 8.64035 9.13999C8.93035 8.84999 9.41035 8.84999 9.70035 9.13999L15.3604 14.8C15.6504 15.09 15.6504 15.57 15.3604 15.86C15.2104 16.01 15.0204 16.08 14.8304 16.08Z"
                  fill="#1B1B1B"
                />
              </svg>
            </div>
          </div>
          <div
            style={{
              overflowY: "auto",
              maxHeight: "300px",
              width: "100%",
              marginTop: "-30px",
            }}
          >
            <table className="players-table">
              <thead>
                <tr>
                  <th>First Name</th>
                  <th>Last Name</th>
                  <th>Phone Number</th>
                  <th>Email</th>
                </tr>
              </thead>
              <tbody>
                {players && players.length > 0 ? (
                  players.map((singlePlayer) => (
                    <tr key={singlePlayer.PlayerId}>
                      <td>{singlePlayer.FirstName}</td>
                      <td>{singlePlayer.LastName}</td>
                      <td>{singlePlayer.PhoneNumber}</td>
                      <td>{singlePlayer.EmailId}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="4" className="loading">
                      <h3>Loading.....</h3>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default AllPlayerPopup;
