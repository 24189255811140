import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Modal from "react-modal";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import swal from "sweetalert";
import { showAlert } from "../../../../../components/SwalAlert/showAlert";

import { API_URLS } from "../../../../../Utils";

Modal.setAppElement("#root");

const Login = ({ isOpen, onClose }) => {
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loginType, setLoginType] = useState("SuperAdmin");
  const [showPassword, setShowPassword] = useState(false);

  const [loginError, setLoginError] = useState(""); //to show user an error in swal or any other alert
  const togglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    if (
      loginType !== "Player" &&
      loginType !== "Coach" &&
      email !== "varnumventures@msn.com"
    ) {
      showAlert("Error", "Please select Player or Coach!", "error");

      return;
    }
    const param = {
      email: email,
      password: password,
      role: loginType,
    };
    try {
      const response = await axios.post(API_URLS.LOGIN_API, param);

      if (response.data && response.data.status === "success") {
        const userData = response.data.data;
        onClose();
        switch (userData.userType) {
          case "SuperAdmin":
            window.sessionStorage.setItem("SuperAdminId", userData.admin.id);
            window.sessionStorage.setItem("UserType", userData.userType);

            navigate(`/admin`);
            break;
          case "Coach":
            window.sessionStorage.setItem("TeamId", userData.TeamId);
            window.sessionStorage.setItem("UserType", userData.userType);

            navigate(`/admin`);
            break;
          case "Player":
            window.sessionStorage.setItem("PlayerId", userData.PlayerId);
            window.sessionStorage.setItem("UserType", userData.userType);

            navigate(`/admin`);
            break;
          default:
            break;
        }
        //console.log("Status 200");
        // window.sessionStorage.setItem("TeamId", response.data.token);
        // navigate(`/admin`);
      } else {
        alert("Please provide correct creds!");
      }
    } catch (error) {
      console.error("Error occurred during request:", error);
      setLoginError(error.message);
      //  alert(error.response.data.message);
      showAlert(
        "Error",
        error.response.data.message || "An unexpected error occurred.",
        "error"
      );
    }
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  // const handleLoginTypeChange = (e) => {
  //   setLoginType(e.target.value);
  // };
  const customStyles = {
    content: {
      width: "80%", // Adjust this value to your desired width
      maxWidth: "1015.2px", // Optional: Set a maximum width
      // Center the modal horizontally
      padding: "20px",
      overflowY: "auto",
      maxHeight: "96%",

      margin: "auto",

      // Add some padding
      // Add other styles as needed
    },
  };
  const settings = {
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    arrows: false,
    dots: true,
    fade: true,
  };
  return (
    <>
      <Modal
        isOpen={isOpen}
        onRequestClose={onClose}
        contentLabel="Rules Modal"
        className="modal-content orange-scrollbar"
        overlayClassName="modal-overlay"
        style={customStyles}
      >
        <div className="registration_modal login_modal " id="login_modal">
          <div className="modal_body">
            <div className="slider_wrap">
              <div className="slick teamReg_slick">
                <Slider {...settings}>
                  <div className="slick_item">
                    <img
                      src="images/team-img-03.png"
                      alt=""
                      style={{ width: "100%" }}
                    />
                    <div className="content">
                      <h3>Proam SoftBall</h3>
                      <small>Estt 2018</small>
                    </div>
                  </div>
                </Slider>
              </div>
            </div>
            <div className="form_wrap">
              <div className="close-button">
                <h3>Login</h3>
                <svg
                  width="30"
                  height="30"
                  viewBox="0 0 24 25"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg "
                  onClick={onClose}
                >
                  <path
                    d="M12 23.25C6.07 23.25 1.25 18.43 1.25 12.5C1.25 6.57 6.07 1.75 12 1.75C17.93 1.75 22.75 6.57 22.75 12.5C22.75 18.43 17.93 23.25 12 23.25ZM12 3.25C6.9 3.25 2.75 7.4 2.75 12.5C2.75 17.6 6.9 21.75 12 21.75C17.1 21.75 21.25 17.6 21.25 12.5C21.25 7.4 17.1 3.25 12 3.25Z"
                    fill="#1B1B1B"
                  />
                  <path
                    d="M9.17035 16.08C8.98035 16.08 8.79035 16.01 8.64035 15.86C8.35035 15.57 8.35035 15.09 8.64035 14.8L14.3004 9.13999C14.5904 8.84999 15.0704 8.84999 15.3604 9.13999C15.6504 9.42999 15.6504 9.90998 15.3604 10.2L9.70035 15.86C9.56035 16.01 9.36035 16.08 9.17035 16.08Z"
                    fill="#1B1B1B"
                  />
                  <path
                    d="M14.8304 16.08C14.6404 16.08 14.4504 16.01 14.3004 15.86L8.64035 10.2C8.35035 9.90998 8.35035 9.42999 8.64035 9.13999C8.93035 8.84999 9.41035 8.84999 9.70035 9.13999L15.3604 14.8C15.6504 15.09 15.6504 15.57 15.3604 15.86C15.2104 16.01 15.0204 16.08 14.8304 16.08Z"
                    fill="#1B1B1B"
                  />
                </svg>
              </div>

              <div className="tabs_wrapper">
                <div className="tab_wrap">
                  <span
                    className={`tab ${loginType === "Player" ? "active" : ""}`}
                    id="Player"
                    onClick={() => setLoginType("Player")}
                  >
                    Player
                  </span>
                  <span
                    className={`tab ${loginType === "Coach" ? "active" : ""}`}
                    id="Coach"
                    onClick={() => setLoginType("Coach")}
                  >
                    Coach
                  </span>
                </div>
                <div className="tabs_container">
                  <form onSubmit={handleLogin} className="row form_wrap">
                    <div className="col-md-12 input_group">
                      <label htmlFor="#">Email</label>
                      <input
                        type="search"
                        placeholder="Email"
                        value={email}
                        onChange={handleEmailChange}
                        name="login_email"
                        className="input"
                      />
                    </div>
                    <div className="col-md-12 input_group">
                      <label htmlFor="#">Password</label>
                      <input
                        type={showPassword ? "text" : "password"}
                        placeholder="Type your Password"
                        value={password}
                        onChange={handlePasswordChange}
                        name="login_password"
                        className="input"
                      />
                      <span
                        className="viewpass"
                        onClick={togglePasswordVisibility}
                      >
                        {/* {showPassword ? "👁️" : "👁️‍🗨️"}{" "} */}
                      </span>
                    </div>
                    {/* <div className="col-md-12 input_group text-right">
                    <a href="#" className="forgot">
                      Forgot Password?
                    </a>
                  </div> */}
                    <div className="col-md-12 btn_wrap">
                      <button className="btn btn_primary w-100">Log In</button>
                    </div>
                  </form>
                </div>
                {/* <div className="col_2">
                <span className="or_text">or sign in with</span>
                <div className="btn_wrap">
                  <a href="#">
                    <img src="images/icons/google.svg" alt="" />
                    Google
                  </a>
                  <a href="#">
                    <img src="images/icons/apple-mac.svg" alt="" />
                    Apple ID
                  </a>
                </div>
                <p>
                  Don’t have account? <a href="#">Create Account</a>
                </p>
              </div> */}
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};
export { Login };
