import React, { useState, useEffect, Fragment } from "react";
import axios from "axios";
import Select from "react-select";
import { Alert } from "../../../components/Alert";
import { useNavigate } from "react-router-dom";
import "./Manage50Teams.css";
import { API_URLS } from "../../../Utils";

import EditTeamModel from "./Components/EditTeamsModal";

const PlayerDropdown = ({ tournaments, onSelectTournament }) => {
  const options = tournaments?.map((tournament) => ({
    value: tournament.ParkId,
    label: `${tournament.FirstName}`,
  }));

  const customStyles = {
    container: (provided) => ({
      ...provided,
      width: "100%",
      maxWidth: "300px",
      margin: "20px auto",
    }),
    control: (provided, state) => ({
      ...provided,
      borderColor: state.isFocused ? "#0056b3" : "#007bff",
      "&:hover": { borderColor: "#0056b3" },
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused
        ? "#e6f7ff"
        : state.isSelected
        ? "#007bff"
        : null,
      color: state.isSelected ? "white" : "black",
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 9999,
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#007bff",
    }),
  };

  return (
    <Select
      options={options}
      onChange={onSelectTournament}
      styles={customStyles}
      placeholder="Select a Tournament..."
      classNamePrefix="custom-react-select"
    />
  );
};
const TeamTable = ({
  teams,
  onEdit,
  onPaid,
  onDelete,
  onManagePlayer,
  onHotel,
  selectedpark,
  reload,
}) => {
  const updatePaymentStatus = async (teamid) => {
    try {
      await axios.patch(API_URLS.UPDATE_PAYMENT_STATUS, {
        ParkId: selectedpark,
        TeamId: teamid,
      });

      reload(selectedpark);
    } catch (error) {
      // console.error("Error updating payment status:", error);
      // return false; // Indicate failure
    }
  };

  const updateSubscriptionPaymentStatus = async (TeamId) => {
    try {
      await axios.post(API_URLS.UPDATE_Annual_Subscription, {
        TeamId: TeamId,
      });

      reload(selectedpark);
    } catch (error) {
      // console.error("Error updating payment status:", error);
      // return false; // Indicate failure
    }
  };

  return (
    <div className="container" style={{ maxWidth: "99%" }}>
      <div className="tabs_container">
        <div className="table-wrapper">
          <table>
            <thead>
              <tr>
                <th>Team Name</th>
                <th>Coach Name</th>
                <th>Selected Day</th>
                <th>Is Tournament Fees Paid?</th>
                <th>Age Level</th>
                <th>Team Level</th>
                <th>Is Annual Subscription Fees Paid?</th>
                <th style={{ minWidth: "200px", margin: "1%" }}>Actions</th>
              </tr>
            </thead>
            <tbody>
              {teams?.map((team) => (
                <tr key={team?.TeamId}>
                  <td>{team?.TeamName}</td>
                  <td>{team?.CoachName}</td>
                  <td>{team?.SelectedDay}</td>
                  <td>{team?.IsPaid ? "Yes" : "No"}</td>
                  <td>{team?.AgeLevel ? team.AgeLevel : "Not Available"}</td>
                  <td>
                    {team?.TeamLevel === 1
                      ? "D"
                      : team?.TeamLevel === 2
                      ? "C"
                      : team?.TeamLevel === 3
                      ? "B"
                      : team?.TeamLevel === 4
                      ? "A"
                      : team?.TeamLevel === 5
                      ? "Major"
                      : "Not Available"}
                  </td>
                  <td>
                    {team?.AunnualFees === null ||
                    team?.AunnualFees === 0 ||
                    team?.AunnualFees === undefined
                      ? "No"
                      : "Yes"}
                  </td>
                  <td>
                    <button
                      className="btn btn_primary text_upp"
                      style={{ margin: "1%" }}
                      onClick={() => updatePaymentStatus(team?.TeamId)}
                    >
                      Tournament Fees Paid ?
                    </button>
                    <button
                      style={{ margin: "1%" }}
                      onClick={() => onManagePlayer(team)}
                      className="btn btn_primary text_upp"
                    >
                      Manage Players
                    </button>
                    <button
                      className="btn btn_primary text_upp"
                      style={{ margin: "1%" }}
                      onClick={() => onDelete(team)}
                    >
                      Delete
                    </button>
                    <button
                      className="btn btn_primary text_upp"
                      style={{ margin: "1%" }}
                      onClick={() => onEdit(team)}
                    >
                      Edit
                    </button>
                    <button
                      className="btn btn_primary text_upp"
                      style={{ margin: "1%" }}
                      onClick={() =>
                        updateSubscriptionPaymentStatus(team?.TeamId)
                      }
                    >
                      Annual Subscription fee Paid ?
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
const Manage50Teams = () => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [tournaments, setTournaments] = useState([]);
  const [selectedTournamentParkId, setSelectedTournamentParkId] =
    useState(null);

  const [deleteTeamInfo, setDeleteTeamInfo] = useState({});
  const [teams, setTeams] = useState([]);
  const [selectedTeam, setSelectedTeam] = useState(null);

  const getTournamentsHandler = async () => {
    setLoading(true);
    try {
      const {
        data: { data },
      } = await axios.get(API_URLS.GET_ALL_50_TOURNAMENTS);
      const sortedData = data?.result[0].sort((a, b) => b.ParkId - a.ParkId);
      setTournaments(sortedData);
      //console.log("sortedData", sortedData);
      setLoading(false);
      // console.log("tournament id", data?.result);
    } catch (error) {
      alert(error.response.data.message);
      setLoading(false);
    }
  };
  const deleteTeamWithParkId = async (TeamId, TourOrSeasonName) => {
    try {
      setDeleteLoading(true);
      await axios.delete(API_URLS.DELETE_TEAM, {
        params: {
          ParkId: selectedTournamentParkId,
          TeamId,
          TourOrSeasonName,
        },
      });
      setDeleteTeamInfo(null);
      getTeamsWithParkId(selectedTournamentParkId);
      setDeleteLoading(false);
    } catch (error) {
      alert(error.response.data.message);
      setDeleteLoading(false);
    }
  };
  const isPaidTeam = async (TeamId) => {
    try {
      await axios.patch(API_URLS.ISPAID_TEAM, {
        ParkId: selectedTournamentParkId,
        TeamId,
      });
      // getTeamsWithParkId(selectedTournamentParkId);
      alert("Success");
    } catch (error) {
      alert(error.response.data.message);
    }
  };
  const getTeamsWithParkId = async (parkId) => {
    try {
      const {
        data: { data },
      } = await axios.get(API_URLS.GET_TEAMS_BY_ID_WITH_TYPE, {
        params: {
          SelectedParkId: parkId,
        },
      });
      // console.log("first", data);
      setSelectedTournamentParkId(parkId);
      setTeams(data?.result);
    } catch (error) {
      alert(error.response.data.message);
    }
  };
  useEffect(() => {
    getTournamentsHandler();
  }, []);
  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          flex: 1,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div className="spinner" />
      </div>
    );
  }
  return (
    <Fragment>
      <h2 style={{ color: "black", fontWeight: "bold", textAlign: "center" }}>
        Manage Teams
      </h2>
      <PlayerDropdown
        tournaments={tournaments}
        onSelectTournament={(el) => getTeamsWithParkId(el.value)}
      />
      <TeamTable
        teams={teams}
        reload={getTeamsWithParkId}
        selectedpark={selectedTournamentParkId}
        onEdit={(team) => {
          setSelectedTeam(team);
        }}
        onPaid={(team) => {
          if (!team.IsPaid) {
            isPaidTeam(team.TeamId);
          }
        }}
        onDelete={(team) => {
          setDeleteTeamInfo({
            TeamId: team.TeamId,
            TourLeagueName: team.TourLeagueName,
          });
        }}
        onManagePlayer={(team) => {
          navigate(`/admin/manage-50_team_players/${team.TeamId}`);
        }}
        onHotel={(team) => {
          navigate(`/admin/manage-Hotel/${team.TeamId}`);
        }}
      />

      <EditTeamModel
        onClose={() => setSelectedTeam(null)}
        isOpen={!!selectedTeam?.TeamId}
        SelectedTeamId={selectedTeam?.TeamId}
        ParkId={selectedTournamentParkId}
        TourLeagueName={selectedTeam?.TourLeagueName}
        tournaments={tournaments}
        getTeamsWithParkId={getTeamsWithParkId}
      />

      {deleteTeamInfo?.TeamId && (
        <Alert
          message="Are you sure want to delete this team?"
          confirmPress={() =>
            deleteTeamWithParkId(
              deleteTeamInfo.TeamId,
              deleteTeamInfo.TourLeagueName
            )
          }
          disable={deleteLoading}
          cancelPress={() => setDeleteTeamInfo(null)}
        />
      )}
    </Fragment>
  );
};

export { Manage50Teams };
