//  Sponsors Section

import React from "react";
import "./sponsors.css";

const SponsorsSection = () => {
  return (
    <div className="sponsors-container">
      <h2 className="sponsors-heading">SPONSORS</h2>
      <p className="sponsors-subheading">We are happy to share the success with our long-term partners.</p>
      <a href="https://thezooclubfl.com/" target="_blank" rel="noopener noreferrer">
      <img
        src="/images/image.png"
        alt="Sponsors Banner"
        className="sponsor-banner-img"
      />
    </a>
    </div>
  );
};

export default SponsorsSection;

