import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import axios from "axios";
import he from "he";

import { API_URLS, BASE_URL } from "../../../../Utils";
import "./upcomingTournament.css";

Modal.setAppElement("#root");

const UpcomingTournaments = () => {
  const [upcomingTournaments, setUpcomingTournaments] = useState([]);
  const [selectedTournament, setSelectedTournament] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [image, setImage] = useState("");
  const [tName, setTName] = useState("");

  const sanitizeHTML = (html) => {
    // Replace all <br> tags with an empty string
    return html.replace(/<br\s*\/?>/gi, "");
  };
  const openDetailsModal = () => {
    setShowModal(true);
  };
  const closeDetailsModal = () => {
    setShowModal(false);
  };

  const formatDate = (dateString) => {
    const options = { month: "long", day: "numeric", year: "numeric" };
    return new Date(dateString).toLocaleDateString("en-US", options);
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(API_URLS.UP_COMING_PROAM_TOURNAMENTS);
        const upcomingTours = response.data.data.result[0]; // Extracting the array of tours

        // Get today's date
        const today = new Date();

        // Filter out past tournaments
        const filteredTours = upcomingTours.filter((tournament) => {
          const tournamentDate = new Date(tournament.ProAmPlayingDate);
          return tournamentDate >= today;
        });

        // Sort tournaments by ascending date
        filteredTours.sort((a, b) => {
          return new Date(a.ProAmPlayingDate) - new Date(b.ProAmPlayingDate);
        });

        setUpcomingTournaments(filteredTours);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const TournamentDetail = async (parkId) => {
    //debugger;
    try {
      const response = await axios.get(API_URLS.GET_PROAM_BY_ParkId, {
        params: {
          selectedParkId: parkId,
        },
      });
      const divisionHtml = response.data.data.result[0].Division;
      const Photo = response.data.data.result[0].TournamentPhoto;
      const Name = response.data.data.result[0].FirstName;
      //console.log(divisionHtml);
      const decodedHtml = he.decode(divisionHtml);
      const sanitizedHtml = sanitizeHTML(decodedHtml);
      setSelectedTournament(sanitizedHtml);
      //setSelectedTournament(he.decode(divisionHtml));
      setImage(Photo);
      setTName(Name);
      openDetailsModal();
      //console.log("from TOurnament detail", response.data.data.result);
    } catch (error) {
      console.error("Error fetching tournament details:", error);
    }
  };
  return (
    <section className="score_section pt-2 pb-3">
      <div className="container">
        <div className="sc_title">
          <h3 shadow-text="SoftBall">CHECK OUT UP-COMING TOURNAMENT</h3>
        </div>

        <div className="table_wrap">
          {upcomingTournaments.map((tournament, index) => (
            <div className="tr" data-aos="fade" data-aos-delay="50" key={index}>
              <div>
                <h3>{tournament.FirstName}</h3>

                <p>{formatDate(tournament.ProAmPlayingDate)}</p>
              </div>
              <div className="td">
                <button
                  className="btn btn_primary text_upp"
                  onClick={() => TournamentDetail(tournament.ParkId)}
                >
                  Details
                </button>
                <a
                  href="https://www.youtube.com/@proam50softball66"
                  className="btn btn_primary text_upp"
                  target="_blank"
                >
                  Watch Stream
                </a>
              </div>
            </div>
          ))}
        </div>
      </div>
      {showModal && (
        <Modal
          isOpen={showModal}
          onRequestClose={closeDetailsModal}
          contentLabel="Rules Modal"
          className="modal-content"
          overlayClassName="modal-overlay"
        >
          <div className="rulesProcedures_modal ">
            <div className="modal_body">
              <div className="img_wrap">
                {image ? (
                  <img
                    src={`${BASE_URL}/api/v1/files/TournamentPhoto/${image}`}
                    alt=""
                    style={{ height: "350px" }}
                  />
                ) : (
                  <div></div>
                )}
                <div className="content">
                  <h2>{tName}</h2>
                </div>
                <div className="rules-close-button">
                  <svg
                    width="30"
                    height="30"
                    viewBox="0 0 24 25"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg "
                    onClick={closeDetailsModal}
                  >
                    <path
                      d="M12 23.25C6.07 23.25 1.25 18.43 1.25 12.5C1.25 6.57 6.07 1.75 12 1.75C17.93 1.75 22.75 6.57 22.75 12.5C22.75 18.43 17.93 23.25 12 23.25ZM12 3.25C6.9 3.25 2.75 7.4 2.75 12.5C2.75 17.6 6.9 21.75 12 21.75C17.1 21.75 21.25 17.6 21.25 12.5C21.25 7.4 17.1 3.25 12 3.25Z"
                      fill="#1B1B1B"
                    />
                    <path
                      d="M9.17035 16.08C8.98035 16.08 8.79035 16.01 8.64035 15.86C8.35035 15.57 8.35035 15.09 8.64035 14.8L14.3004 9.13999C14.5904 8.84999 15.0704 8.84999 15.3604 9.13999C15.6504 9.42999 15.6504 9.90998 15.3604 10.2L9.70035 15.86C9.56035 16.01 9.36035 16.08 9.17035 16.08Z"
                      fill="#1B1B1B"
                    />
                    <path
                      d="M14.8304 16.08C14.6404 16.08 14.4504 16.01 14.3004 15.86L8.64035 10.2C8.35035 9.90998 8.35035 9.42999 8.64035 9.13999C8.93035 8.84999 9.41035 8.84999 9.70035 9.13999L15.3604 14.8C15.6504 15.09 15.6504 15.57 15.3604 15.86C15.2104 16.01 15.0204 16.08 14.8304 16.08Z"
                      fill="#1B1B1B"
                    />
                  </svg>
                </div>
              </div>

              <div className="content">
                {image ? (
                  <div></div>
                ) : (
                  <div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "end",
                        marginTop: "-30px",
                      }}
                    >
                      <svg
                        width="30"
                        height="30"
                        viewBox="0 0 24 25"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg "
                        onClick={closeDetailsModal}
                      >
                        <path
                          d="M12 23.25C6.07 23.25 1.25 18.43 1.25 12.5C1.25 6.57 6.07 1.75 12 1.75C17.93 1.75 22.75 6.57 22.75 12.5C22.75 18.43 17.93 23.25 12 23.25ZM12 3.25C6.9 3.25 2.75 7.4 2.75 12.5C2.75 17.6 6.9 21.75 12 21.75C17.1 21.75 21.25 17.6 21.25 12.5C21.25 7.4 17.1 3.25 12 3.25Z"
                          fill="#1B1B1B"
                        />
                        <path
                          d="M9.17035 16.08C8.98035 16.08 8.79035 16.01 8.64035 15.86C8.35035 15.57 8.35035 15.09 8.64035 14.8L14.3004 9.13999C14.5904 8.84999 15.0704 8.84999 15.3604 9.13999C15.6504 9.42999 15.6504 9.90998 15.3604 10.2L9.70035 15.86C9.56035 16.01 9.36035 16.08 9.17035 16.08Z"
                          fill="#1B1B1B"
                        />
                        <path
                          d="M14.8304 16.08C14.6404 16.08 14.4504 16.01 14.3004 15.86L8.64035 10.2C8.35035 9.90998 8.35035 9.42999 8.64035 9.13999C8.93035 8.84999 9.41035 8.84999 9.70035 9.13999L15.3604 14.8C15.6504 15.09 15.6504 15.57 15.3604 15.86C15.2104 16.01 15.0204 16.08 14.8304 16.08Z"
                          fill="#1B1B1B"
                        />
                      </svg>
                    </div>
                    <center>
                      <h2>{tName}</h2>
                    </center>
                  </div>
                )}

                <center>
                  <div
                    id="divTourDetail"
                    dangerouslySetInnerHTML={{
                      __html: selectedTournament,
                    }}
                  />
                </center>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </section>
  );
};

export { UpcomingTournaments };
