import React from "react";

import { Navbar } from "./Components/Navbar/Navbar";
import { BracketWinner } from "./Components/BracketWinner/BracketWinner";
import { UpcomingTournaments } from "./Components/UpcomingTournaments/UpcomingTournaments";
import { Schedules } from "./Components/Schedules/Schedules";
import { AllPlayers } from "./Components/AllPlayers/AllPlayers";
import { RememberingLegends } from "./Components/RememberingLegens/RememberingLegends";
import { HallofFame } from "./Components/HallofFame/HallofFame";
import { Footer } from "./Components/Footer/Footer";
import Testsms from "./Components/Testsms/Testsms";
import SponsorsSection from "./Components/Sponsers/Sponsors";

function Client() {
  return (
    <>
      <section className="hero_banner">
        <Navbar />
        <BracketWinner />
      </section>
      <SponsorsSection/>
      <UpcomingTournaments />
      <Schedules />
      <AllPlayers />
      <HallofFame />
      <RememberingLegends />
           {/* <Testsms /> */}
      <Footer />
    </>
  );
}

export { Client };
